import { Controller, SubmitHandler, useForm } from "react-hook-form";

import classNames from "classnames";
import { z } from "zod";

import { zodResolver } from "@hookform/resolvers/zod";
import { FormRow, FormRowItem, Submit, Input } from "src/components/ui-components/form-components";
import { BaseFormProps } from "src/components/ui-components/form-components";
import type { CreateOrganizationPayload } from "src/lib/types";

function getValidationSchema() {
  return z.object({
    slug: z
      .string()
      .min(5, `Slug must be at least ${5} characters`)
      .max(100, `Slug must be at most ${100} characters`)
      .regex(
        /^[a-z0-9-]+$/,
        "Slug must only contain lowercase English letters, numbers, and hyphens, with no spaces or special characters"
      ),
    name: z
      .string()
      .min(3, `Name must be at least ${3} characters`)
      .max(100, `Name must be at most ${100} characters`),
    owner_email: z.string().email("Invalid email address"),
  });
}

export const OrganizationForm = ({
  onSubmit,
  className,
  loading,
  disabled,
}: BaseFormProps<CreateOrganizationPayload["organization"]>) => {
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: zodResolver(getValidationSchema()),
    mode: "onTouched",
    defaultValues: {
      slug: "",
      name: "",
      owner_email: "",
    },
  });

  const submit: SubmitHandler<CreateOrganizationPayload["organization"]> = (data) => {
    onSubmit(data);
    reset();
  };

  return (
    <form onSubmit={handleSubmit(submit)} noValidate className={classNames("w-full", className)}>
      <FormRow>
        <FormRowItem>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                required
                type="text"
                label="Name"
                supportingText="Enter the name of this organization"
                error={errors.name}
              />
            )}
          />
        </FormRowItem>
      </FormRow>
      <FormRow>
        <FormRowItem>
          <Controller
            name="slug"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                required
                type="text"
                label="Slug"
                supportingText="Enter a unique slug for this organization"
                error={errors.slug}
              />
            )}
          />
        </FormRowItem>
        <FormRowItem>
          <Controller
            name="owner_email"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                required
                type="email"
                label="Owner Email"
                supportingText="We send an invite to this email"
                error={errors.owner_email}
              />
            )}
          />
        </FormRowItem>
      </FormRow>
      <div className="flex justify-center">
        <Submit disabled={!isValid || disabled} loading={loading} submitText="Create" />
      </div>
    </form>
  );
};
