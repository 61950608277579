import { CirclePlus } from "lucide-react";
import { useTranslation } from "react-i18next";

import { Button } from "./Buttons";
import type { ButtonProps } from "./Buttons";

type AddNewButtonProps = Omit<ButtonProps, "children"> & {
  entity: string;
};

export const AddNewButton = ({ entity, ...rest }: AddNewButtonProps) => {
  const { t } = useTranslation();
  return (
    <Button {...rest} icon={<CirclePlus />}>
      {t("cta.add.entity", { entity })}
    </Button>
  );
};

export default AddNewButton;
