import { Mail } from "lucide-react";
import { useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import classNames from "classnames";
import { z } from "zod";

import { zodResolver } from "@hookform/resolvers/zod";
import { isValidPhoneNumber } from "libphonenumber-js";
import type { BaseFormProps } from "src/components/ui-components/form-components";
import {
  Input,
  PhoneInput,
  FormRow,
  FormRowItem,
  Submit,
} from "src/components/ui-components/form-components";
import type { User, PatchMePayload } from "src/lib/types";

const getPhoneSchema = (required: boolean) => {
  const schema = required ? z.string().min(1, "Phone number is required") : z.string().optional();
  return schema.refine((val) => (val ? isValidPhoneNumber(val) : true), "Invalid phone number");
};

function getValidationSchema() {
  return z.object({
    first_name: z
      .string()
      .min(1, "This field is required")
      .max(124, "First name must be at most 124 characters"),
    last_name: z
      .string()
      .min(1, "This field is required")
      .max(124, "First name must be at most 124 characters"),
    nick_name: z.string().max(124, "Nick name must be at most 124 characters"),
    phone: getPhoneSchema(true),
  });
}

export const PersonalDataForm = ({
  initialData,
  onSubmit,
  className,
  disabled,
  loading,
}: BaseFormProps<PatchMePayload, User>) => {
  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { errors, isValid, isDirty },
  } = useForm<PatchMePayload>({
    resolver: zodResolver(getValidationSchema()),
    defaultValues: {
      first_name: initialData.first_name,
      last_name: initialData.last_name,
      nick_name: initialData.nick_name || "",
      phone: initialData.phone,
    },
    mode: "onChange",
  });

  useEffect(() => {
    reset(getValues());
    console.log("reset");
  }, [initialData]);

  const submit: SubmitHandler<PatchMePayload> = (data) => onSubmit(data);

  return (
    <form onSubmit={handleSubmit(submit)} noValidate className={classNames("w-full", className)}>
      <FormRow>
        <FormRowItem>
          <Controller
            name="first_name"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                value={field.value || ""}
                required
                type="text"
                label="First name"
                error={errors.first_name}
              />
            )}
          />
        </FormRowItem>
        <FormRowItem>
          <Controller
            name="last_name"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                value={field.value || ""}
                required
                type="text"
                label="Last name"
                error={errors.last_name}
              />
            )}
          />
        </FormRowItem>
        <FormRowItem>
          <Controller
            name="nick_name"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                value={field.value || ""}
                type="text"
                label="Nick name"
                error={errors.nick_name}
              />
            )}
          />
        </FormRowItem>
      </FormRow>
      <FormRow>
        <FormRowItem>
          <Input disabled value={initialData.email} type="email" label="Email" inputPostfix={<Mail />} />
        </FormRowItem>
        <FormRowItem>
          <PhoneInput
            placeholder="(000) 000-0000"
            id="phone"
            name="phone"
            label="Phone Number"
            required
            defaultCountry="PL"
            control={control}
            error={errors.phone}
            onChange={(value) => {
              setValue("phone", value || "", { shouldValidate: true });
            }}
          />
        </FormRowItem>
        <FormRowItem />
      </FormRow>
      <div className="flex justify-center">
        <Submit
          loading={loading}
          align="right"
          disabled={!isValid || disabled || !isDirty}
          submitText="Save"
        />
      </div>
    </form>
  );
};

export default PersonalDataForm;
