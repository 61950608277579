import { useTranslation } from "react-i18next";

import { BasePage } from "src/components/layout/page-templates";
import { useAppSelector } from "src/store";
import { getSessionUserSelector } from "src/store/selectors";

export const AdminSettingsPage = () => {
  const { t } = useTranslation();
  const user = useAppSelector(getSessionUserSelector);

  return (
    <BasePage
      title={t("setting.plural", "Settings")}
      description={t("setting.description", "Manage your settings")}>
      {user && (
        <>
          <pre className="mb-5 font-mono text-xs">
            <code>{JSON.stringify(user, null, 2)}</code>
          </pre>
        </>
      )}
    </BasePage>
  );
};

export default AdminSettingsPage;
