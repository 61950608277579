import type { AppNotification } from "src/lib/types";
import { useAppDispatch } from "src/store";
import { setNotificationAction, removeNotificationByIdAction } from "src/store/slices/communicationSlice";

export type BlankNotificationsId = "complete-profile";

export const getBlankNotification = (id: BlankNotificationsId): AppNotification | null => {
  const date = new Date().toISOString();
  switch (id) {
    case "complete-profile":
      return {
        id: "complete-profile",
        read: false,
        date,
        title: "🔔 Please complete your profile",
        message: "Some required information is missing from your profile. Please complete it.",
        action: {
          type: "link",
          label: "Complete profile",
          to: "/user/profile",
        },
      };
    default:
      return null;
  }
};

export const useNotifications = () => {
  const dispatch = useAppDispatch();

  const addNotification = (notification: AppNotification) => {
    dispatch(setNotificationAction(notification));
  };

  const showBlankNotification = (id: BlankNotificationsId) => {
    const notification = getBlankNotification(id);
    if (notification) {
      addNotification(notification);
    }
  };

  const removeNotification = (id: string) => {
    dispatch(removeNotificationByIdAction(id));
  };

  return {
    addNotification,
    showBlankNotification,
    removeNotification,
  };
};
