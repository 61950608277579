import classNames from "classnames";

export type AccentDotProps = React.HTMLAttributes<HTMLSpanElement> & {
  color?: "red" | "green" | "accent";
};

export const AccentDot = ({ className, color = "green", ...rest }: AccentDotProps) => {
  return (
    <span
      {...rest}
      className={classNames(
        "h-[10px] w-[10px] justify-center rounded-full border border-white",
        color === "green" && "bg-green-500 dark:bg-green-600",
        color === "accent" && "bg-cyan-500 dark:bg-cyan-600",
        color === "red" && "bg-red-500 dark:bg-red-600",
        className
      )}
    />
  );
};

export default AccentDot;
