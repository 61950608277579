import { useTranslation } from "react-i18next";

import { BasePage } from "src/components/layout/page-templates";

export const AdminReportsPage = () => {
  const { t } = useTranslation();
  return (
    <BasePage
      title={t("report.plural", "Reports")}
      description={t("report.description", "Here you can generate reports based on your data")}>
      <div>
        <p>TODO: Add report selection and generation</p>
      </div>
    </BasePage>
  );
};

export default AdminReportsPage;
