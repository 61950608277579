import type { AxiosResponse } from "axios";
import moment from "moment";

import type { User, HandledLoginResponse, Organization, UsersResponse } from "src/lib/types";

type UserCalculatedData = {
  invitationStatus: "pending" | "expired" | "accepted";
  invitationExpiredFormatted: string | null;
};

type ModifiedUser = User & {
  calculatedData: UserCalculatedData;
};

const getFullName = (user: User): string => {
  if (user.full_name) {
    return user.full_name;
  }
  if (user.first_name && user.last_name) {
    return `${user.first_name} ${user.last_name}`;
  }
  return user.email;
};

const processUser = (user: User): ModifiedUser => {
  const calculatedData: UserCalculatedData = {
    invitationStatus: "accepted",
    invitationExpiredFormatted: null,
  };
  if (user.invitation_token && user.invitation_expired_at) {
    const expiredAtDate = moment(user.invitation_expired_at);
    const currentDate = moment();
    if (currentDate.isAfter(expiredAtDate)) {
      calculatedData.invitationStatus = "expired";
    } else {
      calculatedData.invitationStatus = "pending";
    }
    calculatedData.invitationExpiredFormatted = expiredAtDate.format("DD-MM-YYYY, hh:mm:ss");
  }
  return {
    ...user,
    full_name: getFullName(user),
    calculatedData: calculatedData,
  };
};

export const handleLoginResponse = (response: AxiosResponse): HandledLoginResponse => {
  return {
    token: response.headers["authorization"],
    user: response.data.user,
    message: response.data.message,
  };
};

export const handleOrganizationResponse = (response: AxiosResponse): Organization => {
  return response.data;
};

export const handleGetMeResponse = (response: AxiosResponse<User>): User => {
  const user = response.data;
  user.full_name = getFullName(user);
  return user;
};

export const handleGetUsersResponse = <T>(data: AxiosResponse["data"]): UsersResponse<T> => {
  return {
    pagy: data.pagy,
    users: data.users.map(processUser),
  };
};
