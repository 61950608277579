import { FeaturePage } from "src/components/layout/page-templates";
import { AddNewButton } from "src/components/ui-components/buttons";
import { NoDataFound } from "src/components/ui-components/info";

export const ExpensesPage = () => {
  const addNewAction = () => {
    console.log("New item handler");
  };

  return (
    <FeaturePage entity="expense" actions={<AddNewButton entity="expense" onClick={addNewAction} />}>
      <NoDataFound entity="expense" />
    </FeaturePage>
  );
};

export default ExpensesPage;
