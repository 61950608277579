import { EllipsisVertical } from "lucide-react";
import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";

import classNames from "classnames";

import { IconButton } from "../buttons/Buttons";
import { useAppGlobalLayer } from "src/hooks";

type ActiveDropdownItemProps = {
  title: React.ReactNode;
  icon?: React.ReactNode;
  disabled?: boolean;
  actionType?: "danger" | "warning";
};

export type DropdownItem =
  | ({ type: "button"; action: () => void } & ActiveDropdownItemProps)
  | ({ type: "link"; to: string } & ActiveDropdownItemProps)
  | { type: "label"; title: React.ReactNode }
  | { type: "node"; content: React.ReactNode }
  | { type: "divider" };

export type DropDownMenuProps = {
  items: DropdownItem[];
  position: {
    top?: number;
    bottom?: number;
    left?: number;
    right?: number;
  };
};

export type GlobalDropdownProps = {
  items: DropdownItem[];
  icon?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
};

const ActiveItemContent = ({ title, icon, disabled, actionType }: ActiveDropdownItemProps) => {
  return (
    <div
      className={classNames(
        "flex w-full items-center whitespace-nowrap px-4 py-2 text-left disabled:cursor-not-allowed",
        disabled && "!muted-text !opacity-50",
        actionType === "danger" ? "!text-red-500" : "muted-text-hover",
        actionType === "warning" ? "!text-yellow-500" : "muted-text-hover"
      )}>
      {icon && <span className="global-dropdown-icon mr-2">{icon}</span>}
      <span>{title}</span>
    </div>
  );
};

const getMenuItemByType = (item: DropdownItem): React.ReactNode => {
  switch (item.type) {
    case "button":
      return (
        <button
          className="w-full border-none p-0 disabled:cursor-not-allowed"
          type="button"
          disabled={item.disabled}
          onClick={item.action}>
          <ActiveItemContent
            icon={item.icon}
            disabled={item.disabled}
            title={item.title}
            actionType={item.actionType}
          />
        </button>
      );
    case "link":
      return (
        <Link to={item.to} className="w-full disabled:cursor-not-allowed">
          <ActiveItemContent
            icon={item.icon}
            disabled={item.disabled}
            title={item.title}
            actionType={item.actionType}
          />
        </Link>
      );
    case "label":
      return (
        <div className="default-border muted-text mt-1 w-full border-b px-2 text-right text-xs uppercase">
          {item.title}
        </div>
      );
    case "node":
      return <div className="w-full px-4 py-2">{item.content}</div>;
    case "divider":
      return <hr className="default-border my-1 w-full border-t" />;
    default:
      return <></>;
  }
};

export const DropDownMenu = ({ items, position }: DropDownMenuProps) => {
  return (
    <div
      id="GLOBAL_DROPDOWN"
      className="default-bg fixed z-50 mt-2 min-w-44 origin-top-right rounded-md border border-gray-200 shadow-lg transition focus:outline-none dark:border-gray-700"
      style={{ ...position }}>
      <div className="py-1">
        {items.map((item, i) => (
          <div key={i}>
            <div className="flex text-sm">{getMenuItemByType(item)}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export const GlobalDropdown = ({ items, children, icon, className }: GlobalDropdownProps) => {
  const { toggleDropdown } = useAppGlobalLayer();
  const triggerRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    const handleResize = () => toggleDropdown(null);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [toggleDropdown]);

  const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (triggerRef.current) {
      const buttonRect = e.currentTarget.getBoundingClientRect();
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      // window quadrant for positioning
      let position: { top?: number; bottom?: number; left?: number; right?: number } = {
        top: buttonRect.bottom,
        right: windowWidth - buttonRect.right,
      };
      if (buttonRect.top < windowHeight * 0.7) {
        if (buttonRect.left > windowWidth / 2) {
          // top-right
          position = { top: buttonRect.bottom, right: windowWidth - buttonRect.right };
        } else {
          // top-left
          position = { top: buttonRect.bottom, left: buttonRect.left };
        }
      } else {
        if (buttonRect.left > windowWidth / 2) {
          // bottom-right
          position = { bottom: windowHeight - buttonRect.top, right: windowWidth - buttonRect.right };
        } else {
          // bottom-left
          position = { bottom: windowHeight - buttonRect.top, left: buttonRect.left };
        }
      }
      toggleDropdown({ items, position }, triggerRef.current);
    }
  };

  return (
    <span
      ref={triggerRef}
      data-role="global-dropdown-trigger"
      onClick={onClick}
      className={classNames("inline", className)}>
      {children || <IconButton>{icon || <EllipsisVertical />}</IconButton>}
    </span>
  );
};

export default GlobalDropdown;
