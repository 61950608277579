import { Mail, Phone, BadgeCheck } from "lucide-react";
import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { FeaturePage } from "src/components/layout/page-templates";
import { AddNewButton } from "src/components/ui-components/buttons";
import { InfoLabel } from "src/components/ui-components/info";
import { Table } from "src/components/ui-components/table";
import type { TableCell, TableRow } from "src/components/ui-components/table";
import { useCoreApi } from "src/hooks";
import type { User, Pagy } from "src/lib/types";

export const AdminUsersPage = () => {
  const navigate = useNavigate();
  const { getUsers } = useCoreApi();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<User[] | null>(null);
  const [pagy, setPagy] = useState<Pagy | null>(null);
  const [page, setPage] = useState(1);

  useEffect(() => {
    setLoading(true);
    getUsers<User>({ page })
      .then(({ users, pagy }) => {
        setPagy(pagy);
        setData(users);
      })
      .finally(() => setLoading(false));
  }, [page]);

  const getTableRows = useCallback(() => {
    if (!data) return [];

    const getRowSells = (user: User) => {
      const invitation = user.calculatedData.invitationStatus;
      const cells: TableCell[] = [
        {
          key: "full_name",
          content: (
            <>
              <p className="font-medium">{user.full_name || "no name"}</p>
              <p className="text-xs text-gray-400">{user.nick_name || "no nickname"}</p>
            </>
          ),
        },
        {
          key: "contacts",
          content: (
            <>
              <a className="link-colors flex items-center" href={`mailto:${user.email}`}>
                <Mail className="h-4" />
                <span className="ml-1">{user.email}</span>
                {user.email_verified && <BadgeCheck className="ml-1 h-4 text-green-500" />}
              </a>
              {user.phone && (
                <a className="link-colors flex items-center" href={`phone:${user.phone}`}>
                  <Phone className="h-4" />
                  <span className="ml-1">{user.phone}</span>
                </a>
              )}
            </>
          ),
        },
        {
          key: "invitation",
          content: (
            <InfoLabel
              color={
                invitation === "accepted" ? "primary" : invitation === "pending" ? "success" : "warning"
              }>
              {invitation}
            </InfoLabel>
          ),
        },
        {
          key: "admin_info",
          content: (
            <div className="text-xs">
              <p>
                <span className="mr-1 text-gray-500">Sign in count: </span>
                <span>{user.sign_in_count}</span>
              </p>
              <p>
                <span className="mr-1 text-gray-500">Last login IP: </span>
                <span>{user.last_login_ip}</span>
              </p>
              <p>
                <span className="mr-1 text-gray-500">Impersonation: </span>
                <span>{user.disable_impersonation ? "disabled" : "enabled"}</span>
              </p>
            </div>
          ),
        },
      ];
      return cells;
    };
    const getRowActions = (user: User): TableRow["actions"] => {
      const invitation = user.calculatedData.invitationStatus;
      const actions: TableRow["actions"] = [
        {
          type: "button",
          title: "Edit",
          action: () => console.log("Edit user", user),
        },
      ];
      if (invitation === "pending") {
        actions.push(
          {
            type: "button",
            title: "Copy invitation link",
            action: () => console.log("Copy invitation link", user.invitation_token),
          },
          {
            type: "button",
            title: "Revoke invitation",
            action: () => console.log("Revoke invitation token", user.invitation_token),
          }
        );
      }
      if (invitation === "expired") {
        actions.push({
          type: "button",
          title: "Resend invitation",
          action: () => console.log("Resend invitation", user),
        });
      }
      actions.push({
        type: "button",
        title: "Delete",
        action: () => console.log("delete user", user),
      });
      return actions;
    };
    return data.map((user) => ({
      id: user.id,
      cells: getRowSells(user),
      actions: getRowActions(user),
    }));
  }, [data]);

  return (
    <FeaturePage
      entity="user"
      total={pagy?.count}
      actions={<AddNewButton entity="user" onClick={() => navigate("/admin/organizations/new")} />}>
      <Table
        pagination={{ pagy, onPageChange: setPage }}
        loading={loading && data === null}
        cols={[
          { key: "full_name", content: "Name" },
          { key: "contacts", content: "Contacts" },
          { key: "invitation", content: "Invitation" },
          { key: "admin_info", content: "Info" },
        ]}
        rows={getTableRows()}
      />
    </FeaturePage>
  );
};
