import React from "react";

import classNames from "classnames";

import { PageTitle, PageDescription } from "src/components/ui-components/typography";

export type BasePageProps = {
  title: React.ReactNode;
  description?: React.ReactNode;
  actions?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
};

export const BasePage = ({ title, description, actions, className, children }: BasePageProps) => {
  return (
    <section className={classNames("min-h-full py-2 md:py-4 lg:py-8", className)}>
      <div className="mb-4 flex flex-wrap items-start justify-between">
        <div>
          <PageTitle>{title}</PageTitle>
          {description && <PageDescription>{description}</PageDescription>}
        </div>
        <div className="mt-2 flex items-center justify-end space-x-2 md:mt-0 md:w-auto">{actions}</div>
      </div>
      <div>{children}</div>
    </section>
  );
};

export default BasePage;
