import { ArrowLeft } from "lucide-react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import classNames from "classnames";
import { z } from "zod";

import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "src/components/ui-components/buttons";
import type { BaseFormProps } from "src/components/ui-components/form-components";
import { Input, FormRow, FormRowItem } from "src/components/ui-components/form-components";

export type ResetPasswordFormData = {
  email: string;
};

function getValidationSchema() {
  return z.object({
    email: z.string().email("Invalid email address"),
  });
}

export const ResetPasswordForm = ({
  initialData,
  disabled,
  loading,
  className,
  onSubmit,
}: BaseFormProps<ResetPasswordFormData, ResetPasswordFormData>) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: zodResolver(getValidationSchema()),
    mode: "onTouched",
    defaultValues: initialData || {
      email: "",
    },
  });

  const navigate = useNavigate();

  const submit: SubmitHandler<ResetPasswordFormData> = (data) => onSubmit(data);

  return (
    <form onSubmit={handleSubmit(submit)} noValidate className={classNames("w-full", className)}>
      <FormRow>
        <FormRowItem>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <Input {...field} required type="email" label="Email" error={errors.email} />
            )}
          />
        </FormRowItem>
      </FormRow>
      <div className="mt-8 flex justify-end gap-4">
        <Button
          type="button"
          variant="secondary"
          disabled={loading}
          icon={<ArrowLeft />}
          onClick={() => navigate({ pathname: "/auth/login" }, { replace: true })}>
          Back to login
        </Button>
        <Button type="submit" disabled={!isValid || disabled || loading}>
          Send reset link
        </Button>
      </div>
    </form>
  );
};
