import { LoaderBar, FullScreenLoader } from "src/components/ui-components/loaders";
import { useAppSelector } from "src/store";
import { getGlobalLoadingSelector, getFullScreenLoadingSelector } from "src/store/selectors";

export function AppLoading({ children }: { children: React.ReactNode }) {
  const globalLoading = useAppSelector(getGlobalLoadingSelector);
  const fullScreenLoading = useAppSelector(getFullScreenLoadingSelector);

  return (
    <>
      {globalLoading && <LoaderBar className="absolute left-0 top-0 z-[100]" />}
      {fullScreenLoading && <FullScreenLoader className="z-[90]" {...fullScreenLoading} />}
      {children}
    </>
  );
}

export default AppLoading;
