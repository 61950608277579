import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import classNames from "classnames";
import { z } from "zod";

import { zodResolver } from "@hookform/resolvers/zod";
import { FormRow, FormRowItem, Submit, Input, Select } from "src/components/ui-components/form-components";
import { BaseFormProps } from "src/components/ui-components/form-components";
import type { CreateNewOrganizationUserPayload, OrganizationUserRole } from "src/lib/types";

const orgRoles: OrganizationUserRole[] = ["owner", "manager", "user"];

function getValidationSchema() {
  return z.object({
    email: z.string().email("Invalid email address"),
    role: z.enum(orgRoles as any),
  });
}

export const OrgUserNewForm = ({
  onSubmit,
  className,
  loading,
  disabled,
}: BaseFormProps<CreateNewOrganizationUserPayload>) => {
  const { t } = useTranslation();
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<CreateNewOrganizationUserPayload>({
    resolver: zodResolver(getValidationSchema()),
    mode: "onTouched",
    defaultValues: {
      email: "",
      role: "user",
    },
  });

  const submit: SubmitHandler<CreateNewOrganizationUserPayload> = (data) => {
    onSubmit(data);
    reset();
  };

  return (
    <form onSubmit={handleSubmit(submit)} noValidate className={classNames("w-full", className)}>
      <FormRow>
        <FormRowItem>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                required
                type="email"
                label="User Email"
                supportingText="We send an invite to this email"
                error={errors.email}
              />
            )}
          />
        </FormRowItem>
      </FormRow>
      <FormRow>
        <FormRowItem>
          <Controller
            name="role"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                required
                label="Role"
                supportingText="Choose the role for this user"
                options={orgRoles.map((role) => ({
                  value: role,
                  label: t(`role.${role}`, role),
                }))}
                onChange={(option) => field.onChange(option?.value)}
                error={errors.role}
              />
            )}
          />
        </FormRowItem>
      </FormRow>
      <div className="flex justify-center">
        <Submit disabled={!isValid || disabled} loading={loading} submitText="Create" />
      </div>
    </form>
  );
};
