import { useState, useEffect } from "react";

import type { AppBreakpoint } from "src/lib/types";

const getBreakpoint = (width: number): AppBreakpoint => {
  switch (true) {
    case width >= 1536:
      return "2xl";
    case width >= 1280:
      return "xl";
    case width >= 1024:
      return "lg";
    case width >= 768:
      return "md";
    case width >= 475:
      return "sm";
    default:
      return "xs";
  }
};

export const useBreakpoint = (): AppBreakpoint => {
  const [breakpoint, setBreakpoint] = useState<AppBreakpoint>(getBreakpoint(window.innerWidth));

  useEffect(() => {
    const handleResize = () => {
      setBreakpoint(getBreakpoint(window.innerWidth));
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return breakpoint;
};

export default useBreakpoint;
