import { X } from "lucide-react";
import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import classNames from "classnames";

import { Button } from "src/components/ui-components/buttons/Buttons";
import { IconButton } from "src/components/ui-components/buttons/Buttons";
import DateTime from "src/components/ui-components/date-time/DateTime";
import { useAppSelector, useAppDispatch } from "src/store";
import { getCommunicationSelector, getIsNotificationsPanelOpenedSelector } from "src/store/selectors";
import { setNotificationPanelOpened } from "src/store/slices/appearanceSlice";

export const AppNotificationsPanel = ({ className }: { className?: string }) => {
  const panelRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useAppDispatch();
  const { notifications } = useAppSelector(getCommunicationSelector);
  const isNotificationsPanelOpened = useAppSelector(getIsNotificationsPanelOpenedSelector);

  const closePanel = () => {
    dispatch(setNotificationPanelOpened(false));
  };

  useEffect(() => {
    if (isNotificationsPanelOpened) {
      document.addEventListener("click", closePanel);
    }

    return () => {
      document.removeEventListener("click", closePanel);
    };
  }, [isNotificationsPanelOpened]);

  useEffect(() => {
    if (isNotificationsPanelOpened && notifications.length === 0) {
      dispatch(setNotificationPanelOpened(false));
    }
  }, [notifications]);

  return (
    <aside
      onClick={(e) => e.stopPropagation()}
      ref={panelRef}
      className={classNames(
        "default-bg flex min-w-80 flex-col overflow-hidden shadow-md transition-transform duration-75",
        isNotificationsPanelOpened ? "translate-x-0" : "translate-x-full",
        className
      )}>
      <div className="default-bg sticky top-0 mb-3 flex justify-between p-2 sm:p-3">
        <span className="muted-text">Notifications</span>
        <IconButton
          tabIndex={isNotificationsPanelOpened ? 0 : -1}
          className="ml-auto"
          onClick={() => dispatch(setNotificationPanelOpened(false))}>
          <X />
        </IconButton>
      </div>
      {notifications.length > 0 ? (
        <div className="overflow-y-auto">
          <ul className="default-border m-2 flex flex-col overflow-hidden rounded-lg border sm:m-3">
            {notifications.map((notification) => (
              <li
                key={notification.id}
                className="default-border default-bg group flex flex-col border-b p-2 transition-colors last:border-b-0 hover:bg-gray-100 sm:p-3 dark:hover:bg-gray-700">
                <div className="mb-2 font-light">
                  <p className="text-sm">{notification.title}</p>
                  <p className="muted-text text-sm group-hover:text-default-text">{notification.message}</p>
                </div>
                <div className="flex items-center justify-between gap-2">
                  <DateTime date={notification.date} className="muted-text text-xs" />
                  {notification.action && (
                    <span className="link-colors ml-auto text-sm" onClick={closePanel}>
                      {notification.action.type === "link" && (
                        <Link
                          to={notification.action.to}
                          className="link-colors"
                          tabIndex={isNotificationsPanelOpened ? 0 : -1}>
                          {notification.action.label}
                        </Link>
                      )}
                      {notification.action.type === "button" && (
                        <button
                          className="link-colors"
                          onClick={notification.action.onClick}
                          tabIndex={isNotificationsPanelOpened ? 0 : -1}>
                          {notification.action.label}
                        </button>
                      )}
                    </span>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div className="my-auto">
          <p className="mb-4 text-center text-sm">All notifications are read</p>
          <Button onClick={() => dispatch(setNotificationPanelOpened(false))} className="mx-auto">
            Close
          </Button>
        </div>
      )}
    </aside>
  );
};

export default AppNotificationsPanel;
