import type { RootState } from "src/store";

// loading slice
export const getLoadingSelector = (state: RootState) => state.loading;
export const getGlobalLoadingSelector = (state: RootState) => state.loading.globalLoading;
export const getFullScreenLoadingSelector = (state: RootState) => state.loading.fullScreenLoading;

// appearance slice
export const getAppearanceSelector = (state: RootState) => state.appearance;
export const getAppearanceCurrentThemeSelector = (state: RootState) => state.appearance.currentTheme;
export const getAppearanceSystemThemeSelector = (state: RootState) => state.appearance.systemTheme;
export const getClientPlatformSelector = (state: RootState) => state.appearance.platform;
export const getIsMobileDeviceSelector = (state: RootState) => state.appearance.isMobileDevice;
export const getIsNavPanelOpenedSelector = (state: RootState) => state.appearance.isNavPanelOpened;
export const getIsNotificationsPanelOpenedSelector = (state: RootState) =>
  state.appearance.isNotificationsPanelOpened;

// session slice
export const getSessionSelector = (state: RootState) => state.session;
export const getSessionRoleSelector = (state: RootState) => state.session.sessionRole;
export const getSessionIsAuthenticatedSelector = (state: RootState) => state.session.isAuthenticated;
export const getSessionIsAdminSelector = (state: RootState) => state.session.isAdmin;
export const getSessionAuthTokenSelector = (state: RootState) => state.session.authToken;
export const getSessionUserSelector = (state: RootState) => state.session.user;
export const getSessionOrganizationSelector = (state: RootState) => state.session.organization;

// communication slice
export const getCommunicationSelector = (state: RootState) => state.communication;
export const getNotificationsSelector = (state: RootState) => state.communication.notifications;
export const getNotificationsCountSelector = (state: RootState) => state.communication.notifications.length;
