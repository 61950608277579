import { useEffect } from "react";

import { ALLOWED_THEMES } from "src/lib/utils";
import { useAppSelector } from "src/store";
import { getAppearanceCurrentThemeSelector } from "src/store/selectors";

export function AppAppearance({ children }: { children: React.ReactNode }) {
  const currentTheme = useAppSelector(getAppearanceCurrentThemeSelector);

  useEffect(() => {
    const root = document.documentElement;
    ALLOWED_THEMES.forEach((theme) => root.classList.remove(theme.value));
    root.classList.add(currentTheme);
  }, [currentTheme]);

  return <>{children}</>;
}

export default AppAppearance;
