import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { AppNotification } from "src/lib/types";

export type CommunicationsState = {
  notifications: AppNotification[];
};

const getInitialState = (): CommunicationsState => {
  return {
    notifications: [],
  };
};

const communicationSlice = createSlice({
  name: "communication",
  initialState: getInitialState(),
  reducers: {
    setNotificationAction: (state, action: PayloadAction<AppNotification>) => {
      if (state.notifications.find((notification) => notification.id === action.payload.id)) {
        return;
      }
      state.notifications.unshift(action.payload);
    },
    removeNotificationByIdAction: (state, action: PayloadAction<string>) => {
      if (!state.notifications.find((notification) => notification.id === action.payload)) {
        return;
      }
      state.notifications = state.notifications.filter((notification) => notification.id !== action.payload);
    },
  },
});

export const { setNotificationAction, removeNotificationByIdAction } = communicationSlice.actions;

export default communicationSlice;
