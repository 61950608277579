import React from "react";

import classNames from "classnames";

export const DialogTitle = ({ children, className, ...rest }: React.HTMLAttributes<HTMLHeadingElement>) => {
  return (
    <h1 {...rest} className={classNames("text-base font-semibold md:text-lg", className)}>
      {children}
    </h1>
  );
};

export default DialogTitle;
