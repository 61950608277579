import React from "react";

import classNames from "classnames";

export type PageTitleProps = React.HTMLAttributes<HTMLHeadingElement>;

export const PageTitle = ({ children, className, ...rest }: PageTitleProps) => {
  return (
    <h1
      {...rest}
      className={classNames("mb-1 text-xl font-bold sm:mb-2 sm:text-2xl md:mb-3 md:text-3xl", className)}>
      {children}
    </h1>
  );
};

export default PageTitle;
