import { Eye, EyeOff } from "lucide-react";
import { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import classNames from "classnames";
import { z } from "zod";

import { zodResolver } from "@hookform/resolvers/zod";
import type { BaseFormProps } from "src/components/ui-components/form-components";
import { Input, Submit, FormRow, FormRowItem } from "src/components/ui-components/form-components";
import { PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH } from "src/lib/constants";
import type { LoginPayload } from "src/lib/types";

export type LoginFormData = LoginPayload["user"];

function getValidationSchema() {
  return z.object({
    email: z.string().email("Invalid email address"),
    password: z
      .string()
      .min(PASSWORD_MIN_LENGTH, `Password must be at least ${PASSWORD_MIN_LENGTH} characters`)
      .max(PASSWORD_MAX_LENGTH, `Password must be at most ${PASSWORD_MAX_LENGTH} characters`),
  });
}

export const LoginForm = ({ disabled, loading, className, onSubmit }: BaseFormProps<LoginFormData>) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    resolver: zodResolver(getValidationSchema()),
    mode: "onTouched",
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const submit: SubmitHandler<LoginFormData> = (data) => onSubmit(data);

  return (
    <form onSubmit={handleSubmit(submit)} noValidate className={classNames("w-full", className)}>
      <fieldset>
        <FormRow>
          <FormRowItem>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <Input {...field} required type="email" label="Email" error={errors.email} />
              )}
            />
          </FormRowItem>
        </FormRow>
        <FormRow>
          <FormRowItem>
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  required
                  type={isPasswordVisible ? "text" : "password"}
                  label="Password"
                  error={errors.password}
                  inputPostfix={
                    isPasswordVisible ? (
                      <Eye className="cursor-pointer" onClick={() => setIsPasswordVisible(false)} />
                    ) : (
                      <EyeOff className="cursor-pointer" onClick={() => setIsPasswordVisible(true)} />
                    )
                  }
                />
              )}
            />
          </FormRowItem>
        </FormRow>
      </fieldset>
      <div className="mb-8 flex justify-end space-x-2 text-sm">
        <Link
          to="/auth/reset-password"
          state={{ email: isValid ? getValues("email") : null }}
          className="text-blue-500 underline">
          Reset password
        </Link>
      </div>
      <Submit fullWidth loading={loading} disabled={!isValid || disabled || loading} submitText="Login" />
    </form>
  );
};
