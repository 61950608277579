import { DoorOpen } from "lucide-react";
import React, { createContext, useState, useRef, ReactElement, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import classNames from "classnames";

import { Toast } from "src/components/ui-components/communication";
import { DropDownMenu } from "src/components/ui-components/dropdowns/GlobalDropdown";
import type { DropDownMenuProps } from "src/components/ui-components/dropdowns/GlobalDropdown";
import { LoaderBar, FullScreenLoader } from "src/components/ui-components/loaders";
import { useAppSession } from "src/hooks";
import { AppToast } from "src/lib/types";
import { useAppSelector } from "src/store";
import {
  getSessionSelector,
  getIsMobileDeviceSelector,
  getGlobalLoadingSelector,
  getFullScreenLoadingSelector,
} from "src/store/selectors";

type AppGlobalLayerContextType = {
  toggleDropdown: (props: DropDownMenuProps | null, triggerRef?: HTMLElement) => void;
  showToast: (message: AppToast["message"], type?: AppToast["type"]) => void;
  cleanUpGlobalLayer: () => void;
};

export const AppGlobalLayerContext = createContext<AppGlobalLayerContextType | null>(null);

export const AppGlobalLayer = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const [toasts, setToasts] = useState<AppToast[]>([]);
  const [dropdown, setDropdown] = useState<ReactElement<DropDownMenuProps> | null>(null);
  const triggerRef = useRef<HTMLElement | null>(null);
  const scrollableAncestorsRef = useRef<HTMLElement[]>([]);
  const globalLoading = useAppSelector(getGlobalLoadingSelector);
  const fullScreenLoading = useAppSelector(getFullScreenLoadingSelector);
  const isMobile = useAppSelector(getIsMobileDeviceSelector);
  const { isAdmin, organization } = useAppSelector(getSessionSelector);
  const { leaveOrganization } = useAppSession();

  useEffect(() => {
    const findScrollableAncestors = (element: HTMLElement) => {
      const scrollableAncestors: HTMLElement[] = [];
      let currentElement: HTMLElement | null = element;
      while (currentElement && currentElement !== document.body) {
        const overflowY = window.getComputedStyle(currentElement).overflowY;
        if (
          overflowY === "scroll" ||
          overflowY === "auto" ||
          currentElement.scrollHeight > currentElement.clientHeight
        ) {
          scrollableAncestors.push(currentElement);
        }
        currentElement = currentElement.parentElement;
      }
      return scrollableAncestors;
    };

    // Handle click outside dropdown to close it
    const handleClick = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      if (target.closest("[data-role='global-dropdown-trigger']")) {
        return;
      }
      toggleDropdown(null);
    };

    // Handle scroll event for all scrollable ancestors
    const handleScroll = () => {
      if (dropdown && triggerRef.current) {
        const buttonRect = triggerRef.current.getBoundingClientRect();
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;
        let position: { top?: number; bottom?: number; left?: number; right?: number } = {
          top: buttonRect.bottom,
          right: windowWidth - buttonRect.right,
        };
        if (buttonRect.top < windowHeight / 2) {
          if (buttonRect.left > windowWidth / 2) {
            position = { top: buttonRect.bottom, right: windowWidth - buttonRect.right };
          } else {
            position = { top: buttonRect.bottom, left: buttonRect.left };
          }
        } else {
          if (buttonRect.left > windowWidth / 2) {
            position = { bottom: windowHeight - buttonRect.top, right: windowWidth - buttonRect.right };
          } else {
            position = { bottom: windowHeight - buttonRect.top, left: buttonRect.left };
          }
        }
        setDropdown(React.cloneElement(dropdown, { position }));
      }
    };

    document.addEventListener("click", handleClick);

    if (triggerRef.current) {
      scrollableAncestorsRef.current = findScrollableAncestors(triggerRef.current);
      scrollableAncestorsRef.current.forEach((ancestor) => ancestor.addEventListener("scroll", handleScroll));
    }

    return () => {
      document.removeEventListener("click", handleClick);
      scrollableAncestorsRef.current.forEach((ancestor) =>
        ancestor.removeEventListener("scroll", handleScroll)
      );
    };
  }, [dropdown]);

  const toggleDropdown = (props: DropDownMenuProps | null, newTriggerRef?: HTMLElement) => {
    if (props && newTriggerRef) {
      // If dropdown is already open, close it
      if (triggerRef.current === newTriggerRef) {
        setDropdown(null);
        triggerRef.current = null;
      } else {
        // Open new dropdown
        triggerRef.current = newTriggerRef;
        setDropdown(<DropDownMenu {...props} />);
      }
    } else {
      // Close dropdown if props is null
      setDropdown(null);
      triggerRef.current = null;
    }
  };

  const showToast = (message: AppToast["message"], type?: AppToast["type"]) => {
    const id = Math.random().toString(36).substr(2, 9);
    const toast: AppToast = {
      id,
      message,
      type: type || "info",
    };
    setToasts((prevToasts) => [...prevToasts, toast]);
    setTimeout(() => {
      setToasts((prevToasts) => prevToasts.filter((t) => t.id !== id));
    }, 5000);
  };

  return (
    <AppGlobalLayerContext.Provider
      value={{
        toggleDropdown,
        showToast,
        cleanUpGlobalLayer: () => {
          setDropdown(null);
          setToasts([]);
        },
      }}>
      {globalLoading && <LoaderBar className="absolute left-0 top-0 z-[100]" />}
      {fullScreenLoading && <FullScreenLoader className="z-[90]" {...fullScreenLoading} />}
      {dropdown}
      {isAdmin && Boolean(organization) ? (
        <button
          className={classNames(
            "fixed z-40 mx-1 rounded-md border border-orange-500 bg-orange-500 px-2 py-1 text-xs text-white hover:border-orange-500 hover:bg-white hover:text-orange-500",
            isMobile ? "bottom-20 left-4" : "bottom-2 left-2"
          )}
          onClick={() => {
            leaveOrganization();
            navigate("/admin/organizations");
          }}>
          <DoorOpen size={30} />
        </button>
      ) : null}
      {toasts.length > 0 && (
        <div className="fixed bottom-20 right-4 z-50">
          {toasts.map((toast) => (
            <Toast
              key={toast.id}
              toast={toast}
              onClose={() => setToasts((prevToasts) => prevToasts.filter((t) => t.id !== toast.id))}
            />
          ))}
        </div>
      )}
      {children}
    </AppGlobalLayerContext.Provider>
  );
};

export default AppGlobalLayer;
