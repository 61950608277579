import { ChevronLeft, ChevronRight, ChevronFirst, ChevronLast } from "lucide-react";

import classNames from "classnames";

import type { ButtonProps } from "src/components/ui-components/buttons/Buttons";
import type { Pagy } from "src/lib/types";

const ICON_CLASS = "h-6 w-6";

export type PaginationProps = {
  pagy: Pagy | null;
  onPageChange: (page: number) => void;
  className?: string;
};

export const Pagination = ({ pagy, onPageChange, className }: PaginationProps) => {
  if (!pagy) {
    return null;
  }

  const handleNavigation = (page: number) => {
    onPageChange(page);
  };

  return (
    <div className={classNames("flex items-center justify-between", className)}>
      <div>
        <span className="muted-text text-sm">
          Page <span className="default-text">{pagy.page}</span> from{" "}
          <span className="default-text">{pagy.pages}</span>
        </span>
      </div>
      <div className="flex space-x-4">
        <PageButton
          onClick={() => handleNavigation(1)}
          disabled={pagy.page === 1}
          title="Go to the first page">
          <ChevronFirst className={ICON_CLASS} />
        </PageButton>
        <PageButton
          onClick={() => handleNavigation(pagy.page - 1)}
          disabled={!pagy.prev}
          title="Go to the previous page">
          <ChevronLeft className={ICON_CLASS} />
        </PageButton>
        {pagy.series.map((item) => {
          return (
            <div key={item}>
              {
                <>
                  {typeof item === "number" ? (
                    <PageButton onClick={() => handleNavigation(item)}>{item}</PageButton>
                  ) : (
                    <>
                      {item === "gap" ? (
                        <span>..</span>
                      ) : (
                        <span className="default-text underline" title="Current page">
                          {item}
                        </span>
                      )}
                    </>
                  )}
                </>
              }
            </div>
          );
        })}
        <PageButton
          onClick={() => handleNavigation(pagy.page + 1)}
          disabled={!pagy.next}
          title="Go to the next page">
          <ChevronRight className={ICON_CLASS} />
        </PageButton>
        <PageButton
          onClick={() => handleNavigation(pagy.pages)}
          disabled={pagy.page === pagy.pages}
          title="Go to the last page">
          <ChevronLast className={ICON_CLASS} />
        </PageButton>
      </div>
    </div>
  );
};

const PageButton = ({
  className,
  children,
  ...rest
}: {
  active?: boolean;
} & ButtonProps) => {
  return (
    <button
      {...rest}
      className={classNames(
        "muted-text min-w-6 hover:text-default-text disabled:cursor-not-allowed disabled:text-muted-text",
        className
      )}>
      {children}
    </button>
  );
};

export default Pagination;
