import { Route, Routes, Navigate } from "react-router-dom";

import { FormComponentsKit, ButtonsKit, TypographyKit } from "./kits";
import { NotFoundPage } from "src/components/pages";

export const UIKitRoutes = () => {
  return (
    <Routes>
      <Route path="/form-components" element={<FormComponentsKit />} />
      <Route path="/buttons" element={<ButtonsKit />} />
      <Route path="/typography" element={<TypographyKit />} />
      <Route path="/" element={<Navigate to="/admin/ui-kit/form-components" />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default UIKitRoutes;
