import { BasePage } from "src/components/layout/page-templates";
import { PageTitle, PageDescription, SectionTitle, Paragraph } from "src/components/ui-components/typography";

export const TypographyKit = () => {
  return (
    <BasePage title="Typography">
      <div>
        <PageTitle>Page Title. h1</PageTitle>
        <PageDescription>
          Page description. You can yse this to describe page functionality. p
        </PageDescription>
        <SectionTitle>Section Title. You can use this for section naming. h2</SectionTitle>
        <SectionTitle>Section Title. You can use this for section naming. h2</SectionTitle>
        <Paragraph>
          Paragraph. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sapiente, beatae quo? Sed ea
          quasi consequuntur mollitia, natus eos deserunt dolores architecto distinctio amet est totam commodi
          beatae odit provident! Perferendis.
        </Paragraph>
        <Paragraph>
          Paragraph. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sapiente, beatae quo? Sed ea
          quasi consequuntur mollitia, natus eos deserunt dolores architecto distinctio amet est totam commodi
          beatae odit provident! Perferendis.
        </Paragraph>
        <Paragraph>
          Example of link colors. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sapiente, beatae
          quo?
          <a className="link-colors mx-1" href="#">
            natus eos deserunt dolores architecto distinctio amet
          </a>
          quasi consequuntur mollitia, est totam commodi beatae odit provident! Perferendis.
        </Paragraph>
      </div>
    </BasePage>
  );
};
