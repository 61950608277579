import { Route, Routes, Navigate } from "react-router-dom";

import { CarsPage } from "src/components/features/cars/pages";
import OrganizationDashboard from "src/components/features/dashboard/OrganizationDashboard";
import { ExpensesPage } from "src/components/features/expenses/pages";
import { OrdersPage } from "src/components/features/orders/pages";
import { CompanyReportsPage } from "src/components/features/reports/pages";
import { CompanySettingsPage } from "src/components/features/settings/pages";
import { OrgUsersPage } from "src/components/features/users/pages";
import { NotFoundPage } from "src/components/pages";
import { useAppSelector } from "src/store";
import { getSessionRoleSelector } from "src/store/selectors";

export const OrganizationRoutes = () => {
  const sessionRole = useAppSelector(getSessionRoleSelector);

  return sessionRole === "org" ? (
    <Routes>
      <Route path="/" element={<Navigate to="/org/dashboard" />} />
      <Route path="/dashboard" element={<OrganizationDashboard />} />
      <Route path="/orders" element={<OrdersPage />} />
      <Route path="/cars" element={<CarsPage />} />
      <Route path="/users" element={<OrgUsersPage />} />
      <Route path="/expenses" element={<ExpensesPage />} />
      <Route path="/reports" element={<CompanyReportsPage />} />
      <Route path="/settings" element={<CompanySettingsPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  ) : (
    <NotFoundPage />
  );
};
