import { CirclePlus, Trash2, Pencil, Check, Bell } from "lucide-react";

import { BasePage } from "src/components/layout/page-templates";
import { Button, IconButton, ButtonGroup } from "src/components/ui-components/buttons";
import { Section } from "src/components/ui-components/layout-components";
import { SectionTitle } from "src/components/ui-components/typography";

export const ButtonsKit = () => {
  return (
    <BasePage title="Buttons">
      <Section>
        <div className="mb-4">
          <SectionTitle>Sizes:</SectionTitle>
          <div className="flex flex-wrap items-center gap-2 p-2">
            <Button size="xs">size xs</Button>
            <Button size="sm">size sm</Button>
            <Button size="md">size md</Button>
            <Button size="lg">size lg</Button>
          </div>
        </div>
        <div className="mb-4">
          <SectionTitle>Appearances:</SectionTitle>
          <div className="flex flex-wrap items-center gap-2 p-2">
            <Button variant="primary">Primary</Button>
            <Button variant="secondary">Secondary</Button>
            <Button variant="primary" color="danger">
              Danger
            </Button>
            <Button variant="secondary" color="danger">
              Danger
            </Button>
            <Button variant="primary" color="success">
              Success
            </Button>
            <Button variant="secondary" color="success">
              Success
            </Button>
          </div>
        </div>
        <div className="mb-4">
          <SectionTitle>Disabled:</SectionTitle>
          <div className="flex flex-wrap items-center gap-2 p-2">
            <Button variant="primary" disabled>
              Primary
            </Button>
            <Button variant="secondary" disabled>
              Secondary
            </Button>
            <Button variant="primary" color="danger" disabled>
              Danger
            </Button>
            <Button variant="secondary" color="danger" disabled>
              Danger
            </Button>
            <Button variant="primary" color="success" disabled>
              Success
            </Button>
            <Button variant="secondary" color="success" disabled>
              Success
            </Button>
          </div>
        </div>
        <div className="mb-4">
          <SectionTitle>With an icon:</SectionTitle>
          <div className="flex flex-wrap items-center gap-2 p-2">
            <Button variant="secondary" color="default" size="xs" icon={<Pencil />} iconPosition="left">
              Edit
            </Button>
            <Button variant="primary" icon={<CirclePlus />} iconPosition="left">
              Add new
            </Button>
            <Button variant="primary" color="danger" icon={<Trash2 />} iconPosition="right">
              Delete
            </Button>
            <Button variant="secondary" color="danger" icon={<Trash2 />} iconPosition="left">
              Delete
            </Button>
            <Button variant="secondary" color="success" icon={<Check />} iconPosition="right">
              Success
            </Button>
          </div>
        </div>
        <div className="mb-4">
          <SectionTitle>Icon buttons:</SectionTitle>
          <div className="flex flex-wrap items-center gap-2 p-2">
            <IconButton variant="secondary" color="default" size="xs">
              <Pencil />
            </IconButton>
            <IconButton variant="primary" color="default" size="xs">
              <Pencil />
            </IconButton>
            <IconButton variant="secondary" color="danger" size="sm">
              <Trash2 />
            </IconButton>
            <IconButton variant="secondary" color="danger" size="sm">
              <Trash2 />
            </IconButton>
            <IconButton variant="primary" color="success" size="md">
              <Check />
            </IconButton>
            <IconButton variant="secondary" color="success" size="md">
              <Check />
            </IconButton>
            <IconButton variant="primary" size="md" showDot="top-left" dotColor="red">
              <Bell />
            </IconButton>
            <IconButton variant="secondary" size="md" showDot="top-right" dotColor="accent">
              <Bell />
            </IconButton>
            <IconButton variant="primary" size="md" color="success" showDot="bottom-left" dotColor="green">
              <Bell />
            </IconButton>
            <IconButton variant="primary" size="md" color="danger" showDot="bottom-right" dotColor="red">
              <Bell />
            </IconButton>
            <IconButton variant="secondary" color="default" size="lg">
              <CirclePlus />
            </IconButton>
            <IconButton variant="primary" color="default" size="lg">
              <CirclePlus />
            </IconButton>
          </div>
        </div>
        <div className="mb-4">
          <SectionTitle>Group:</SectionTitle>
          <div className="flex flex-wrap items-center gap-2 p-2">
            <ButtonGroup>
              <Button>Button 1</Button>
              <Button>Button 2</Button>
              <Button variant="secondary">Button 3</Button>
            </ButtonGroup>
          </div>
        </div>
      </Section>
    </BasePage>
  );
};
