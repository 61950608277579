import { Route, Routes, Navigate } from "react-router-dom";

import { UserProfilePage } from "src/components/features/user/pages";
import { NotFoundPage } from "src/components/pages";

export const UserRoutes = () => {
  return (
    <Routes>
      <Route path="/profile" element={<UserProfilePage />} />
      <Route path="/" element={<Navigate to="/user/profile" />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
