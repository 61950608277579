import React from "react";

import classNames from "classnames";

export type ParagraphProps = React.HTMLAttributes<HTMLParagraphElement>;

export const Paragraph = ({ children, className, ...rest }: ParagraphProps) => {
  return (
    <p {...rest} className={classNames("mb-2 text-sm font-light sm:mb-3 sm:text-base", className)}>
      {children}
    </p>
  );
};

export default Paragraph;
