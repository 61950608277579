import React, { forwardRef, isValidElement, useEffect, useState } from "react";
import { ErrorOption, ControllerRenderProps, FieldValues } from "react-hook-form";
import BasePhoneInput, { Props } from "react-phone-number-input/react-hook-form";
import "react-phone-number-input/style.css";
import ReactSelect from "react-select";

import classNames from "classnames";

import { Label, SupportingText } from "src/components/ui-components/typography";

/**
 * See autofill styles here: src/styles/global.css
 */

const getInputBorderStyles = (hasPrefix: boolean, hasPostfix: boolean) => {
  switch (true) {
    case hasPrefix && !hasPostfix:
      return "rounded-r-lg border-l-0";
    case !hasPrefix && hasPostfix:
      return "rounded-l-lg border-r-0";
    case hasPrefix && hasPostfix:
      return "rounded-none border-r-0 border-l-0";
    default:
      return "rounded-lg";
  }
};

const INPUT_BORDER_BASE_STYLES =
  "default-border border focus:border-gray-400 focus:outline-none group-hover:border-gray-400 focus:dark:border-gray-400 group-hover:dark:border-gray-400 disabled:cursor-not-allowed disabled:text-muted-text";

export const InputPrefix = ({ children, type }: { children: React.ReactNode; type: "pre" | "post" }) => {
  let child = children;
  if (isValidElement(children)) {
    const { props } = children;
    child = React.cloneElement(children as React.ReactElement, {
      ...props,
      // eslint-disable-next-line react/prop-types
      className: "h-5, w-5 " + props.className || "",
    });
  }

  return (
    <span
      className={classNames(
        "muted-text default-bg flex items-center py-1",
        INPUT_BORDER_BASE_STYLES,
        type === "pre" && "order-0 rounded-l-lg border-r-0 pl-3",
        type === "post" && "order-2 rounded-r-lg border-l-0 pr-3"
      )}>
      <span className="my-auto">{child}</span>
    </span>
  );
};

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  label?: string;
  supportingText?: React.ReactNode;
  inputPrefix?: React.ReactNode;
  inputPostfix?: React.ReactNode;
  error?: ErrorOption;
}

export const Input = forwardRef(
  (
    { className, label, supportingText, inputPrefix, inputPostfix, error, ...rest }: InputProps,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    return (
      <div className={classNames("w-full", className)}>
        {label && (
          <Label htmlFor={rest.name} required={rest.required}>
            {label}
          </Label>
        )}
        <div className={classNames("group flex w-full items-stretch text-sm")}>
          <input
            {...rest}
            ref={ref}
            className={classNames(
              INPUT_BORDER_BASE_STYLES,
              "default-text order-1 w-full bg-default-bg px-3 py-2 pr-12 disabled:bg-gray-100 dark:disabled:bg-gray-700",
              "[&:disabled~span]:cursor-not-allowed [&:disabled~span]:bg-gray-100 [&:disabled~span]:dark:bg-gray-700 [&:focus~span]:border-gray-400",
              getInputBorderStyles(!!inputPrefix, !!inputPostfix)
            )}
          />
          {inputPrefix && <InputPrefix type="pre">{inputPrefix}</InputPrefix>}
          {inputPostfix && <InputPrefix type="post">{inputPostfix}</InputPrefix>}
        </div>
        <SupportingText className={classNames("mt-1", error && "!text-red-500")}>
          {error ? error.message : supportingText}
        </SupportingText>
      </div>
    );
  }
);
Input.displayName = "Input";

export type PhoneInputProps<FormValues extends FieldValues> = Props<
  {
    id?: string;
    placeholder?: string;
    required?: boolean;
    error?: ErrorOption;
    label?: string;
    supportingText?: React.ReactNode;
  },
  FormValues
> & { onChange?: (e: any) => void };

export function PhoneInput<FormValues extends FieldValues>({
  control,
  name,
  label,
  required,
  supportingText,
  error,
  onChange,
  ...rest
}: PhoneInputProps<FormValues>) {
  return (
    <div className="w-full">
      {label && (
        <Label htmlFor={name} required={required}>
          {label}
        </Label>
      )}
      <BasePhoneInput
        defaultCountry="PL"
        international
        countryCallingCodeEditable={false}
        focusInputOnCountrySelection={false}
        {...rest}
        name={name}
        control={control}
        onChange={onChange}
        className="flex items-center"
        inputComponent={Input}
      />
      <SupportingText className={classNames("mt-1", error && "!text-red-500")}>
        {error ? error.message : supportingText}
      </SupportingText>
    </div>
  );
}

export type SelectOption<T = string> = {
  value: T;
  label: string;
};

export interface SelectProps extends Omit<ControllerRenderProps, "ref"> {
  options: SelectOption[];
  disabled?: boolean;
  required?: boolean;
  className?: string;
  label?: string;
  supportingText?: React.ReactNode;
  inputPrefix?: React.ReactNode;
  inputPostfix?: React.ReactNode;
  error?: ErrorOption;
}

export const Select = forwardRef(
  (
    { options, disabled, label, required, supportingText, error, ...rest }: SelectProps,
    ref: React.ForwardedRef<any>
  ) => {
    const [selectedOption, setSelectedOption] = useState<SelectOption | null>(null);

    useEffect(() => {
      if (rest.value) {
        const option = options.find((option) => option.value === rest.value);
        if (option) setSelectedOption(option);
      }
    }, [rest.value]);

    return (
      <div className={classNames("w-full")}>
        {label && (
          <Label htmlFor={rest.name} required={required}>
            {label}
          </Label>
        )}
        <ReactSelect<SelectOption>
          {...rest}
          ref={ref}
          options={options}
          value={selectedOption}
          isDisabled={disabled}
          classNames={{
            container: () => "!border-none text-sm group",
            control: (state) => {
              return classNames(
                "!border-gray-200 dark:!border-gray-700 !border !rounded-lg !outline-none hover:!border-gray-400 dark:hover:!border-gray-400 !transition-none !shadow-none",
                state.selectProps.isDisabled
                  ? "cursor-not-allowed !text-muted-text !bg-gray-100 dark:!bg-gray-700"
                  : "!bg-default-bg",
                state.isFocused && "!border-gray-400 dark:!border-gray-400"
              );
            },
            valueContainer: () => {
              return classNames("!text-default-text");
            },
            singleValue: (state) => {
              return classNames(
                "!text-default-text",
                state.selectProps.isDisabled ? "!text-muted-text" : "!text-default-text"
              );
            },
            input: () => "!m-0 !p-0 !text-default-text",
            indicatorSeparator: () => "dark:!bg-gray-700 !bg-gray-200",
            placeholder: () => "!text-muted-text",
            menu: () =>
              "!border-gray-200 dark!border-gray-400 !rounded-lg border !bg-default-bg default-text",
            option: (state) => {
              return classNames(
                "text-sm !text-default-text",
                state.isSelected ? "!bg-gray-200 dark:!bg-gray-700" : "!bg-default-bg",
                state.isFocused ? "!bg-gray-100 dark:!bg-gray-600" : "!bg-default-bg"
              );
            },
          }}
        />
        <SupportingText className={classNames("mt-1", error && "!text-red-500")}>
          {error ? error.message : supportingText}
        </SupportingText>
      </div>
    );
  }
);
Select.displayName = "Select";
