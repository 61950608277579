import React from "react";

import classNames from "classnames";

export type SupportingTextProps = React.HTMLAttributes<HTMLParagraphElement>;

export const SupportingText = ({ children, className, ...rest }: SupportingTextProps) => {
  return (
    <p {...rest} className={classNames("muted-text text-xs font-light", className)}>
      {children}
    </p>
  );
};

export default SupportingText;
