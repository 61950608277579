import React from "react";

import classNames from "classnames";

export type SectionTitleProps = React.HTMLAttributes<HTMLHeadingElement>;

export const SectionTitle = ({ children, className, ...rest }: SectionTitleProps) => {
  return (
    <h3 {...rest} className={classNames("mb-2 text-base font-semibold md:mb-3 md:text-lg", className)}>
      {children}
    </h3>
  );
};

export default SectionTitle;
