import { X, CircleAlert, CircleCheck, CircleX, Info } from "lucide-react";
import { useRef } from "react";

import classNames from "classnames";

import { useSwipe } from "src/hooks";
import { AppToast } from "src/lib/types";

const icons = {
  info: <Info />,
  success: <CircleCheck />,
  warning: <CircleAlert />,
  error: <CircleX />,
};

export const Toast = ({ toast, onClose }: { toast: AppToast; onClose: () => void }) => {
  const swipeElementRef = useRef<HTMLDivElement>(null);

  useSwipe(swipeElementRef, {
    onSwipe: (direction) => {
      if (direction === "left" || direction === "right") {
        onClose();
      }
    },
  });

  return (
    <div
      ref={swipeElementRef}
      className="border-1 default-border default-bg mb-2 flex min-w-64 max-w-96 items-start justify-between gap-3 rounded-lg border p-2 text-sm">
      <span
        className={classNames(
          "shrink-0",
          toast.type === "info" && "text-blue-500",
          toast.type === "success" && "text-green-500",
          toast.type === "warning" && "text-yellow-500",
          toast.type === "error" && "text-red-500"
        )}>
        {icons[toast.type]}
      </span>
      <span className="flex-1">{toast.message}</span>
      <button className="muted-text-hover shrink-0">
        <X onClick={onClose} className="h-5 w-5" />
      </button>
    </div>
  );
};

export default Toast;
