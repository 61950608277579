import { useState } from "react";
import { useTranslation } from "react-i18next";

import PersonalDataForm from "src/components/features/user/forms/PersonalDataForm";
import { BasePage } from "src/components/layout/page-templates";
import { Section } from "src/components/ui-components/layout-components";
import { useCoreApi } from "src/hooks";
import type { PatchMePayload } from "src/lib/types";
import { useAppSelector } from "src/store";
import { getSessionSelector } from "src/store/selectors";

export const UserProfilePage = () => {
  const { t } = useTranslation();
  const sessionData = useAppSelector(getSessionSelector);
  const [loading, setLoading] = useState(false);
  const { patchMe } = useCoreApi();

  const onSubmit = (data: PatchMePayload) => {
    setLoading(true);
    patchMe(data)
      .then((updatedUser) => {
        console.log("User updated", updatedUser);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <BasePage title={t("profile", "Profile")} description={t("profile", "Manage your profile")}>
      <Section>
        {sessionData.user && (
          <PersonalDataForm loading={loading} initialData={sessionData.user} onSubmit={onSubmit} />
        )}
      </Section>
    </BasePage>
  );
};

export default UserProfilePage;
