import { Eye, EyeOff } from "lucide-react";
import { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import classNames from "classnames";
import { z } from "zod";

import { zodResolver } from "@hookform/resolvers/zod";
import type { BaseFormProps } from "src/components/ui-components/form-components";
import { Input, FormRow, FormRowItem, Submit } from "src/components/ui-components/form-components";
import { PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH } from "src/lib/constants";

function getValidationSchema() {
  return z
    .object({
      password: z
        .string()
        .min(PASSWORD_MIN_LENGTH, `Password must be at least ${PASSWORD_MIN_LENGTH} characters`)
        .max(PASSWORD_MAX_LENGTH, `Password must be at most ${PASSWORD_MAX_LENGTH} characters`),
      password_confirmation: z
        .string()
        .min(PASSWORD_MIN_LENGTH, `Password must be at least ${PASSWORD_MIN_LENGTH} characters`)
        .max(PASSWORD_MAX_LENGTH, `Password must be at most ${PASSWORD_MAX_LENGTH} characters`),
    })
    .superRefine(({ password_confirmation, password }, ctx) => {
      if (password_confirmation !== password) {
        ctx.addIssue({
          code: "custom",
          message: "The password confirmation does not match the password",
          path: ["password_confirmation"],
        });
      }
    });
}

export type CreatePasswordFormData = {
  password: string;
  password_confirmation: string;
};

export const CreatePasswordForm = ({
  onSubmit,
  className,
  disabled,
  loading,
}: BaseFormProps<CreatePasswordFormData>) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: zodResolver(getValidationSchema()),
    mode: "onTouched",
    defaultValues: {
      password: "",
      password_confirmation: "",
    },
  });

  const submit: SubmitHandler<CreatePasswordFormData> = (data) => onSubmit(data);

  return (
    <form onSubmit={handleSubmit(submit)} noValidate className={classNames("w-full", className)}>
      <FormRow>
        <FormRowItem>
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                required
                type={isPasswordVisible ? "text" : "password"}
                label="Password"
                supportingText="Create a password that is at least 8 characters long"
                error={errors.password}
                inputPostfix={
                  isPasswordVisible ? (
                    <Eye className="cursor-pointer" onClick={() => setIsPasswordVisible(false)} />
                  ) : (
                    <EyeOff className="cursor-pointer" onClick={() => setIsPasswordVisible(true)} />
                  )
                }
              />
            )}
          />
        </FormRowItem>
      </FormRow>
      <FormRow>
        <FormRowItem>
          <Controller
            name="password_confirmation"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                required
                type={isPasswordVisible ? "text" : "password"}
                label="Password confirmation"
                supportingText="Type your password again to confirm it"
                error={errors.password_confirmation}
              />
            )}
          />
        </FormRowItem>
      </FormRow>
      <Submit fullWidth disabled={!isValid || disabled} loading={loading} submitText="Create password" />
    </form>
  );
};
