import React, { useEffect } from "react";

import classNames from "classnames";

import MobileQuickPanel from "./MobileQuickPanel";
import Header from "src/components/layout/components/Header";
import NavigationPanel from "src/components/layout/components/NavigationPanel";
import { AppNotificationsPanel } from "src/components/ui-components/communication";
import { useBreakpoint } from "src/hooks";
import type { AppBreakpoint } from "src/lib/types";
import { useAppSelector, useAppDispatch } from "src/store";
import { getIsNavPanelOpenedSelector, getIsMobileDeviceSelector } from "src/store/selectors";
import { setNavPanelOpened } from "src/store/slices/appearanceSlice";

const PANEL_TOGGLE_BREAKPOINTS: AppBreakpoint[] = ["xs", "sm", "md"];

function PageWithSideNav({ children }: { children: React.ReactNode }) {
  const dispatch = useAppDispatch();
  const breakpoint = useBreakpoint();
  const isMobile = useAppSelector(getIsMobileDeviceSelector);
  const isNavPanelOpened = useAppSelector(getIsNavPanelOpenedSelector);

  useEffect(() => {
    if (PANEL_TOGGLE_BREAKPOINTS.includes(breakpoint)) {
      if (isNavPanelOpened) {
        dispatch(setNavPanelOpened(false));
      }
    } else {
      if (!isNavPanelOpened && !isMobile) {
        dispatch(setNavPanelOpened(true));
      }
    }
  }, [breakpoint]);

  useEffect(() => {
    if (isMobile) {
      return;
    }
    const handleClickOutside = (event: MouseEvent) => {
      if ((event.target as Element).closest("#MAIN_NAVIGATION_PANEL") === null && isNavPanelOpened) {
        dispatch(setNavPanelOpened(false));
      }
    };
    if (PANEL_TOGGLE_BREAKPOINTS.includes(breakpoint)) {
      document.addEventListener("click", handleClickOutside);
    }

    return () => document.removeEventListener("click", handleClickOutside);
  }, [isNavPanelOpened]);

  return (
    <div
      className={classNames(
        "muted-bg relative flex h-dvh flex-col overflow-hidden",
        isMobile ? "landscape:flex-row" : "flex-col"
      )}>
      <div
        className={classNames(
          "relative flex",
          isMobile ? "h-[calc(100dvh-64px)] w-full landscape:h-full landscape:w-[calc(100%-74px)]" : "h-dvh"
        )}>
        <NavigationPanel
          onItemClick={() => {
            if (isMobile) {
              dispatch(setNavPanelOpened(false));
            }
          }}
          className={classNames(
            "absolute left-0 top-0 z-10 h-full transition-transform duration-75",
            isMobile ? "w-full" : "w-[256px] md:static",
            isNavPanelOpened ? "translate-x-0" : "-translate-x-full md:hidden md:translate-x-0"
          )}
        />
        <div
          className={classNames(
            "relative flex h-full w-full flex-1 flex-col px-3 md:px-5 lg:px-8",
            !isMobile && isNavPanelOpened ? "md:w-[calc(100%-256px)]" : ""
          )}>
          <AppNotificationsPanel
            className={classNames(
              "absolute right-0 z-20 transition-transform duration-75",
              isMobile ? "top-0 h-full" : "top-[55px] h-[calc(100%-55px)] w-64"
            )}
          />
          {!isMobile && <Header className="h-[56px] w-full" />}
          <section
            className={classNames(
              "relative overflow-y-auto overflow-x-hidden pb-5",
              !isMobile ? "h-[calc(100%-56px)]" : "h-full"
            )}>
            {children}
          </section>
        </div>
      </div>
      {isMobile && (
        <MobileQuickPanel className="relative z-40 h-[64px] w-full shrink-0 landscape:h-full landscape:w-[74px]" />
      )}
    </div>
  );
}

export default PageWithSideNav;
