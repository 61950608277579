import { useNotifications } from "./useNotifications";
import Cookies from "js-cookie";
import { STORAGE_KEYS } from "src/lib/constants";
import type { AppSessionRole, Organization, User } from "src/lib/types";
import { useAppSelector, useAppDispatch } from "src/store";
import { getSessionAuthTokenSelector } from "src/store/selectors";
import type { AppSessionState } from "src/store/slices/sessionSlice";
import { setPartialSessionAction, setInitialSessionAction } from "src/store/slices/sessionSlice";

type StoredSessionData = {
  authToken: string;
  isAdmin: boolean;
  orgId: Organization["id"] | null;
};

const storeData = (data: StoredSessionData) => {
  Cookies.set(STORAGE_KEYS.APP_SESSION, JSON.stringify(data), { secure: true });
};

export const getStoredData = (): StoredSessionData | null => {
  const storedData = Cookies.get(STORAGE_KEYS.APP_SESSION);
  return storedData ? JSON.parse(storedData) : null;
};

const isProfileCompleted = (user: User) => {
  return user.first_name && user.last_name;
};

type UseAppSessionReturnType = {
  authToken: AppSessionState["authToken"];
  storedAuthSession: StoredSessionData | null;
  setAuthToken: (token: string) => void;
  setAuthSessionData: (data: { user: User; organization: Organization | null }) => void;
  updateSessionUser: (user: User) => void;
  destroyAuthSession: () => void;
  enterOrganization: (organization: Organization) => void;
  leaveOrganization: () => void;
};

export function useAppSession(): UseAppSessionReturnType {
  const dispatch = useAppDispatch();
  const { showBlankNotification, removeNotification } = useNotifications();
  const authToken = useAppSelector(getSessionAuthTokenSelector);

  return {
    authToken,
    storedAuthSession: getStoredData(),
    setAuthToken: (token) => {
      dispatch(setPartialSessionAction({ authToken: token }));
    },
    setAuthSessionData: (data) => {
      if (!authToken) {
        throw new Error("Auth token is required to set session data");
      }
      const { user, organization } = data;
      const isAdmin = user.admin;
      // start with user-customer role
      let sessionRole: AppSessionRole = "user";
      if (organization && organization.user_role !== "user") {
        sessionRole = "org";
      }
      if (isAdmin) {
        sessionRole = "admin";
      }
      storeData({
        authToken,
        isAdmin,
        orgId: organization ? organization.id : null,
      });
      dispatch(
        setPartialSessionAction({
          sessionRole,
          user,
          organization,
          isAuthenticated: true,
          isAdmin,
        })
      );
      if (!isProfileCompleted(user)) {
        showBlankNotification("complete-profile");
      }
    },
    updateSessionUser: (user: User) => {
      dispatch(setPartialSessionAction({ user }));
      if (!isProfileCompleted(user)) {
        showBlankNotification("complete-profile");
      } else {
        removeNotification("complete-profile");
      }
    },
    destroyAuthSession: () => {
      Cookies.remove(STORAGE_KEYS.APP_SESSION);
      dispatch(setInitialSessionAction());
      removeNotification("complete-profile");
    },
    enterOrganization: (organization: Organization) => {
      dispatch(setPartialSessionAction({ organization, sessionRole: "org" }));
    },
    leaveOrganization: () => {
      dispatch(setPartialSessionAction({ organization: null, sessionRole: "admin" }));
    },
  };
}
