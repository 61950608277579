import React from "react";

import classNames from "classnames";

export type PageDescriptionProps = React.HTMLAttributes<HTMLParagraphElement>;

export const PageDescription = ({ children, className, ...rest }: PageDescriptionProps) => {
  return (
    <p {...rest} className={classNames("muted-text text-xs font-light sm:text-base", className)}>
      {children}
    </p>
  );
};

export default PageDescription;
