import React from "react";

import classNames from "classnames";

type LabelProps = React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement> & {
  className?: string;
  required?: boolean;
};

export const Label = ({ children, className, required, ...props }: LabelProps) => {
  return (
    <label className={classNames("mb-1 block text-sm dark:text-muted-text", className)} {...props}>
      <span>{children}</span>
      {required && <span className="ml-1 text-red-500">*</span>}
    </label>
  );
};
