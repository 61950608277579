import React, { isValidElement } from "react";

import classNames from "classnames";

import { AccentDot } from "src/components/ui-components/info";
import type { AccentDotProps } from "src/components/ui-components/info/AccentDot";

export type ButtonVariant = "primary" | "secondary";
export type ButtonSize = "xs" | "sm" | "md" | "lg";
export type ButtonColor = "default" | "danger" | "success";

export type CommonButtonProps = {
  variant?: ButtonVariant;
  size?: ButtonSize;
  color?: ButtonColor;
  className?: string;
  icon?: React.ReactNode;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const getColors = (variant: ButtonVariant, color: ButtonColor) => {
  switch (true) {
    case variant === "primary" && color === "default":
      return {
        bg: "bg-gray-900 hover:bg-gray-800 dark:bg-gray-100 dark:hover:bg-gray-200",
        text: "text-gray-100  dark:text-gray-900",
        border: "border-gray-900 dark:border-gray-100",
      };
    case variant === "primary" && color === "danger":
      return {
        bg: "bg-red-500 hover:bg-red-400  dark:bg-red-500 dark:hover:bg-red-400",
        text: "text-white  dark:text-gray-100",
        border: "border-red-500 dark:border-red-500",
      };
    case variant === "primary" && color === "success":
      return {
        bg: "bg-green-500 hover:bg-green-400  dark:bg-green-500 dark:hover:bg-green-400",
        text: "text-white  dark:text-gray-100",
        border: "border-green-500 dark:border-green-500",
      };
    case variant === "secondary" && color === "default":
      return {
        bg: "bg-default-bg",
        text: "text-gray-800 dark:text-gray-300",
        border: "border-gray-300 dark:border-gray-500 hover:border-gray-400 dark:hover:border-gray-400",
      };
    case variant === "secondary" && color === "danger":
      return {
        bg: "bg-default-bg",
        text: "text-red-500 dark:text-red-500",
        border: "border-gray-300 dark:border-gray-500 hover:border-gray-400 dark:hover:border-gray-400",
      };
    case variant === "secondary" && color === "success":
      return {
        bg: "bg-default-bg",
        text: "text-green-500  dark:text-green-500",
        border: "border-gray-300 dark:border-gray-500 hover:border-gray-400 dark:hover:border-gray-400",
      };
    default:
      return {
        bg: "",
        text: "",
        border: "",
      };
  }
};

const getButtonSize = (size: ButtonSize) => {
  switch (size) {
    case "xs":
      return "px-2 py-[2px]";
    case "sm":
      return "h-8 px-3 py-[2px]";
    case "md":
      return "h-9 px-3 py-[2px]";
    case "lg":
      return "h-11 px-4 py-[2px]";
    default:
      return "";
  }
};

const getIconButtonSize = (size: ButtonSize) => {
  switch (size) {
    case "xs":
      return "h-6 w-6";
    case "sm":
      return "h-7 w-7";
    case "md":
      return "h-8 w-8";
    case "lg":
      return "h-9 w-9";
    default:
      return "";
  }
};

const getDotPosition = (position: string) => {
  switch (position) {
    case "top-right":
      return "top-[-3px] right-[-3px]";
    case "top-left":
      return "top-[-3px] left-[-3px]";
    case "bottom-right":
      return "bottom-[-3px] right-[-3px]";
    case "bottom-left":
      return "bottom-[-3px] left-[-3px]";
    default:
      return "";
  }
};

export type ButtonProps = CommonButtonProps & { iconPosition?: "left" | "right" };

export const Button = ({
  variant = "primary",
  size = "md",
  color = "default",
  children,
  className,
  icon,
  iconPosition = "left",
  ...rest
}: ButtonProps) => {
  const { bg, text, border } = getColors(variant, color);

  let iconChild = icon;
  if (isValidElement(icon)) {
    const { props } = icon;
    iconChild = React.cloneElement(icon as React.ReactElement, {
      ...props,
      className: "h-4, w-4 " + props.className || "",
    });
  }

  return (
    <button
      {...rest}
      className={classNames(
        "ring-cyan-500 ring-offset-1 focus:ring-1",
        "flex shrink-0 transform items-center justify-center rounded-lg border text-sm tracking-wide transition-colors duration-150 ease-in-out focus:outline-none disabled:cursor-not-allowed disabled:border-gray-300 disabled:bg-default-bg disabled:text-gray-500 disabled:hover:bg-default-bg",
        bg,
        text,
        border,
        getButtonSize(size),
        className
      )}>
      {iconChild && (
        <span className={classNames(iconPosition === "right" ? "order-2 ml-2" : "order-0 mr-2")}>
          {iconChild}
        </span>
      )}
      <span className="order-1">{children}</span>
    </button>
  );
};

export type IconButtonProps = Omit<CommonButtonProps, "icon"> & {
  children?: React.ReactNode;
  showDot?: "top-right" | "top-left" | "bottom-right" | "bottom-left";
  dotColor?: AccentDotProps["color"];
};

export const IconButton = ({
  variant = "secondary",
  size = "md",
  color = "default",
  className,
  children,
  showDot,
  dotColor = "accent",
  ...rest
}: IconButtonProps) => {
  const { bg, text, border } = getColors(variant, color);

  let iconChild = children;
  if (isValidElement(children)) {
    const { props } = children;
    iconChild = React.cloneElement(children as React.ReactElement, {
      ...props,
      className: "h-full, w-full " + props.className || "",
    });
  }

  return (
    <button
      {...rest}
      className={classNames(
        "ring-cyan-500 ring-offset-1 focus:ring-1",
        "relative flex transform items-center justify-center rounded-md border p-1 text-sm transition-colors duration-150 ease-in-out focus:outline-none disabled:cursor-not-allowed",
        bg,
        text,
        border,
        getIconButtonSize(size),
        className
      )}>
      {iconChild}
      {showDot && <AccentDot className={classNames("absolute", getDotPosition(showDot))} color={dotColor} />}
    </button>
  );
};
