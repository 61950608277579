import { Trash } from "lucide-react";
import { useEffect, useState, useCallback } from "react";

import { OrgUserNewForm } from "../forms/OrgUserNewForm";
import { FeaturePage } from "src/components/layout/page-templates";
import { AddNewButton } from "src/components/ui-components/buttons";
import { Dialog, openDialogById, closeDialogById } from "src/components/ui-components/dialog/Dialog";
import { Section } from "src/components/ui-components/layout-components";
import { Table } from "src/components/ui-components/table";
import type { TableProps } from "src/components/ui-components/table";
import { useCoreApi } from "src/hooks";
import { useAppGlobalLayer } from "src/hooks";
import type { OrgUser, Pagy, CreateNewOrganizationUserPayload } from "src/lib/types";
import { useAppSelector } from "src/store";
import { getSessionUserSelector } from "src/store/selectors";

const NEW_USER_DIALOG_ID = "createNewUser";

export const OrgUsersPage = () => {
  const { showToast } = useAppGlobalLayer();
  const user = useAppSelector(getSessionUserSelector);
  const { getUsers, createOrgUser, deleteOrgUser } = useCoreApi();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<OrgUser[] | null>(null);
  const [pagy, setPagy] = useState<Pagy | null>(null);
  const [page, setPage] = useState(1);

  const loadUsers = () => {
    setLoading(true);
    getUsers<OrgUser>({ page })
      .then(({ users, pagy }) => {
        setPagy(pagy);
        setData(users);
      })
      .finally(() => setLoading(false));
  };

  const createUser = (data: CreateNewOrganizationUserPayload) => {
    closeDialogById(NEW_USER_DIALOG_ID);
    createOrgUser(data)
      .then(() => {
        showToast("User created successfully", "success");
        loadUsers();
      })
      .catch((error) => {
        showToast(error.message, "error");
      });
  };

  const deleteUser = (userId: number) => {
    if (!window.confirm("Are you sure you want to delete this user?")) return;
    deleteOrgUser(userId)
      .then(() => {
        showToast("User deleted successfully", "success");
        loadUsers();
      })
      .catch((error) => {
        showToast(error.message, "error");
      });
  };

  useEffect(loadUsers, [page]);

  const getTableRows = useCallback((): TableProps["rows"] => {
    if (!data) return [];
    return data.map((orgUser) => ({
      id: orgUser.id,
      cells: [
        {
          key: "full_name",
          content: (
            <>
              <p className="text-base font-medium">
                <span>{orgUser.full_name || "no name"}</span>
                {user?.id === orgUser.id && <span className="ml-2 text-sm">(You)</span>}
              </p>
              <a className="link-colors flex items-center" href={`mailto:${orgUser.email}`}>
                {orgUser.email}
              </a>
            </>
          ),
        },
        {
          key: "role",
          content: <span className="capitalize">{orgUser.role as string}</span>,
        },
      ],
      actions: [
        {
          type: "button",
          title: "Delete",
          actionType: "danger",
          icon: <Trash />,
          disabled: orgUser.id === user?.id,
          action: () => deleteUser(orgUser.id),
        },
      ],
    }));
  }, [data]);

  return (
    <FeaturePage
      entity="user"
      total={pagy?.count}
      actions={<AddNewButton entity="user" onClick={() => openDialogById(NEW_USER_DIALOG_ID)} />}>
      <Table
        pagination={{ pagy, onPageChange: setPage }}
        loading={loading && data === null}
        cols={[
          { key: "full_name", content: "Name" },
          { key: "role", content: "Role" },
        ]}
        rows={getTableRows()}
      />
      <Dialog id={NEW_USER_DIALOG_ID} dialogTitle="Create a new user">
        <Section className="w-[600px] max-w-full">
          <OrgUserNewForm loading={loading} disabled={loading} onSubmit={createUser} />
        </Section>
      </Dialog>
    </FeaturePage>
  );
};
