import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getSystemTheme, getCurrentTheme, detectMobileDevice, detectOSPlatform } from "src/lib/utils";

export type AppTheme = "light" | "dark";

export type AppAppearanceState = {
  currentTheme: AppTheme;
  systemTheme: AppTheme;
  platform: "mac" | "windows" | "linux" | "android" | "ios" | "unknown";
  isMobileDevice: boolean;
  isNavPanelOpened?: boolean;
  isNotificationsPanelOpened: boolean;
};

const getInitialState = (): AppAppearanceState => {
  const isMobile = detectMobileDevice();
  return {
    currentTheme: getCurrentTheme(),
    systemTheme: getSystemTheme(),
    platform: detectOSPlatform(),
    isMobileDevice: isMobile,
    isNavPanelOpened: !isMobile,
    isNotificationsPanelOpened: false,
  };
};

const appearanceSlice = createSlice({
  name: "appearance",
  initialState: getInitialState(),
  reducers: {
    setCurrentThemeAction: (state, action: PayloadAction<AppAppearanceState["currentTheme"]>) => {
      state.currentTheme = action.payload;
    },
    setInitialAppearanceState: (state) => {
      const initialState = getInitialState();
      state.currentTheme = initialState.currentTheme;
      state.systemTheme = initialState.systemTheme;
    },
    setNavPanelOpened: (state, { payload }: PayloadAction<boolean>) => {
      if (payload === true && state.isNotificationsPanelOpened && state.isMobileDevice) {
        state.isNotificationsPanelOpened = false;
      }
      state.isNavPanelOpened = payload;
    },
    setNotificationPanelOpened: (state, { payload }: PayloadAction<boolean>) => {
      if (payload === true && state.isNavPanelOpened && state.isMobileDevice) {
        state.isNavPanelOpened = false;
      }
      state.isNotificationsPanelOpened = payload;
    },
  },
});

export const {
  setCurrentThemeAction,
  setInitialAppearanceState,
  setNavPanelOpened,
  setNotificationPanelOpened,
} = appearanceSlice.actions;

export default appearanceSlice;
