import classNames from "classnames";

import ThemeSelector from "src/components/ui-components/theme/ThemeSelector";

export const AuthScreen = ({ className, children }: { children: React.ReactNode; className?: string }) => {
  return (
    <div
      className={classNames(
        "default-bg relative flex min-h-dvh flex-col items-center justify-center p-3 md:p-5",
        className
      )}>
      <div className="absolute right-3 top-3">
        <ThemeSelector />
      </div>
      {children}
    </div>
  );
};

const AuthTitle = ({ children }: { children: React.ReactNode }) => {
  return <h1 className="text-center text-base lg:text-lg">{children}</h1>;
};

const ErrorCode = ({ children }: { children: React.ReactNode }) => {
  return <h2 className="mb-3 block text-6xl font-semibold md:text-7xl lg:text-8xl">{children}</h2>;
};

export const AuthWaitingScreen = ({ text }: { text?: string }) => {
  return (
    <AuthScreen>
      <AuthTitle>{text || "Loading..."}</AuthTitle>
    </AuthScreen>
  );
};

export const AuthErrorScreen = ({ code, onRetry }: { code: number; onRetry: () => void }) => {
  return (
    <AuthScreen>
      <ErrorCode>{code}</ErrorCode>
      <AuthTitle>Service is temporary unavailable. Please try again later.</AuthTitle>
      <div className="mt-6">
        <button className="border px-24 py-2" onClick={onRetry}>
          Retry
        </button>
      </div>
    </AuthScreen>
  );
};

export const AuthUnavailableScreen = () => {
  return (
    <AuthScreen>
      <ErrorCode>503</ErrorCode>
      <AuthTitle>Service is under maintenance. Please try again later.</AuthTitle>
    </AuthScreen>
  );
};
