import { House, LibraryBig } from "lucide-react";
import { Link } from "react-router-dom";

import classNames from "classnames";

type NavItemType = {
  icon: React.ReactNode;
  title: string;
  path: string;
  disabled: boolean;
};

const items: NavItemType[] = [
  {
    icon: (
      <span className="rounded-md border bg-indigo-600 p-2">
        <House className="h-8 w-8 text-indigo-200" />
      </span>
    ),
    title: "Home",
    path: "/",
    disabled: false,
  },
  {
    icon: (
      <span className="rounded-md border bg-emerald-600 p-2">
        <LibraryBig className="h-8 w-8 text-emerald-200" />
      </span>
    ),
    title: "Knowledge Centre",
    path: "/knowledge-centre",
    disabled: true,
  },
];

export const NotFoundPage = () => {
  const msg = "Whoops! That page doesn’t exist.";

  return (
    <div className="flex h-full flex-col items-center justify-center p-3 md:p-5">
      <h1 className="text-center">
        <strong className="mb-3 block text-6xl md:text-7xl lg:text-9xl">404</strong>
        <span className="text-xl sm:text-2xl md:text-3xl">{msg}</span>
      </h1>
      <ul className="mt-12 flex flex-wrap items-stretch justify-center space-y-2">
        {items.map((item) => (
          <li key={item.path} className="m-2 h-full w-56 md:w-72">
            {item.disabled ? (
              <NavItem icon={item.icon} title={item.title} disabled={item.disabled} />
            ) : (
              <Link to={item.path} className="block">
                <NavItem icon={item.icon} title={item.title} disabled={item.disabled} />
              </Link>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

const NavItem = ({ icon, title, disabled }: Omit<NavItemType, "path">) => {
  return (
    <div
      className={classNames(
        "flex h-full flex-col items-center justify-center rounded-lg border p-4 transition-colors",
        disabled ? "" : "hover:bg-gray-100 dark:hover:bg-slate-800"
      )}>
      {icon}
      <p
        className={classNames(
          "mt-2 text-center text-sm font-bold sm:text-base",
          disabled ? "opacity-60" : ""
        )}>
        {title}
      </p>
    </div>
  );
};
