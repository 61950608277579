import { useEffect, useState } from "react";

import { detectMobileDevice } from "src/lib/utils";

type SwipeDirection = "left" | "right" | "up" | "down" | null;

interface UseSwipeOptions {
  threshold?: number;
  onSwipe?: (direction: SwipeDirection) => void;
}

export const useSwipe = (
  ref: React.RefObject<HTMLElement>,
  { threshold = 50, onSwipe }: UseSwipeOptions = {}
) => {
  const [swipeDirection, setSwipeDirection] = useState<SwipeDirection>(null);

  useEffect(() => {
    const element = ref.current;
    if (!element || !detectMobileDevice()) {
      return; // Skip swipe logic if not on a mobile device
    }

    let touchStartX = 0;
    let touchStartY = 0;
    let touchEndX = 0;
    let touchEndY = 0;

    const handleTouchStart = (event: TouchEvent) => {
      touchStartX = event.touches[0].clientX;
      touchStartY = event.touches[0].clientY;
    };

    const handleTouchEnd = (event: TouchEvent) => {
      touchEndX = event.changedTouches[0].clientX;
      touchEndY = event.changedTouches[0].clientY;

      const deltaX = touchEndX - touchStartX;
      const deltaY = touchEndY - touchStartY;

      // Determine if the swipe is horizontal or vertical
      if (Math.abs(deltaX) > Math.abs(deltaY) && Math.abs(deltaX) > threshold) {
        const direction = deltaX > 0 ? "right" : "left";
        setSwipeDirection(direction);
        onSwipe?.(direction);
      } else if (Math.abs(deltaY) > threshold) {
        const direction = deltaY > 0 ? "down" : "up";
        setSwipeDirection(direction);
        onSwipe?.(direction);
      }
    };

    element.addEventListener("touchstart", handleTouchStart);
    element.addEventListener("touchend", handleTouchEnd);

    return () => {
      element.removeEventListener("touchstart", handleTouchStart);
      element.removeEventListener("touchend", handleTouchEnd);
    };
  }, [ref, threshold, onSwipe]);

  return swipeDirection;
};
