import { Search, Pencil } from "lucide-react";
import { useForm, Controller } from "react-hook-form";

import { z } from "zod";

import { zodResolver } from "@hookform/resolvers/zod";
import { isValidPhoneNumber } from "libphonenumber-js";
import { BasePage } from "src/components/layout/page-templates";
import {
  Input,
  Select,
  PhoneInput,
  FormRow,
  FormRowItem,
  Submit,
} from "src/components/ui-components/form-components";
import { Section } from "src/components/ui-components/layout-components";
import { SectionTitle } from "src/components/ui-components/typography";

const getPhoneSchema = (required: boolean) => {
  const schema = required ? z.string().min(1, "Phone number is required") : z.string().optional();
  return schema.refine((val) => (val ? isValidPhoneNumber(val) : true), "Invalid phone number");
};

function getValidationSchema() {
  return z.object({
    phone_1: getPhoneSchema(false),
    phone_2: getPhoneSchema(true),
    phone_3: getPhoneSchema(false),
  });
}

const selectOptions = [
  { value: "user", label: "User" },
  { value: "manager", label: "Manager" },
  { value: "employer", label: "Employer" },
];

export const FormComponentsKit = () => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(getValidationSchema()),
    defaultValues: {
      phone_1: "+380961881467",
      phone_2: "",
      phone_3: "",
      select_1: "",
      select_2: "",
      select_3: "",
    },
    mode: "onChange",
  });

  return (
    <BasePage title="Form Components">
      <Section>
        <div className="mb-8">
          <SectionTitle>Input types:</SectionTitle>
          <FormRow>
            <FormRowItem>
              <Input label="Company Name" placeholder="Mercedes Motors" />
            </FormRowItem>
          </FormRow>
          <FormRow>
            <FormRowItem>
              <Input label="First Name" placeholder="John" supportingText="Type your first name here" />
            </FormRowItem>
            <FormRowItem>
              <Input label="Last Name" placeholder="Doe" />
            </FormRowItem>
          </FormRow>
          <FormRow>
            <FormRowItem>
              <Input label="Icon prefix" placeholder="type to search ..." inputPrefix={<Search />} />
            </FormRowItem>
            <FormRowItem>
              <Input label="Icon postfix" inputPostfix={<Pencil />} />
            </FormRowItem>
          </FormRow>
          <FormRow>
            <FormRowItem>
              <Input label="Text prefix" inputPrefix="USD" placeholder="..." />
            </FormRowItem>
            <FormRowItem>
              <Input inputPostfix="%" label="Text postfix" placeholder="..." />
            </FormRowItem>
            <FormRowItem>
              <Input
                label="Text prefix and postfix"
                inputPrefix="USD"
                inputPostfix="%"
                placeholder="..."
                required
              />
            </FormRowItem>
          </FormRow>
          <FormRow>
            <FormRowItem>
              <Input
                disabled
                label="First Name / disabled"
                placeholder="My first name"
                supportingText="Type your first name here"
              />
            </FormRowItem>
            <FormRowItem>
              <Input
                disabled
                label="Icon prefix / disabled"
                value="Some entered value"
                inputPrefix={<Search />}
              />
            </FormRowItem>
            <FormRowItem>
              <Input
                disabled
                label="Text prefix and postfix / disabled"
                inputPrefix="USD"
                inputPostfix="%"
                placeholder="..."
              />
            </FormRowItem>
          </FormRow>
        </div>
        <div className="mb-8">
          <SectionTitle>Phone input:</SectionTitle>
          <FormRow>
            <FormRowItem>
              <PhoneInput
                placeholder="+000 000000000"
                name="phone_1"
                label="Phone Number"
                defaultCountry="UA"
                control={control}
                error={errors.phone_1}
                onChange={(value) => {
                  setValue("phone_1", value || "", { shouldValidate: true });
                }}
              />
            </FormRowItem>
            <FormRowItem>
              <PhoneInput
                placeholder="+000 000000000"
                name="phone_2"
                label="Phone Number"
                defaultCountry="PL"
                control={control}
                error={errors.phone_2}
                onChange={(value) => {
                  setValue("phone_2", value || "", { shouldValidate: true });
                }}
              />
            </FormRowItem>
            <FormRowItem>
              <PhoneInput
                placeholder="+000 000000000"
                name="phone_3"
                label="Phone Number"
                required
                disabled
                defaultCountry="RU"
                control={control}
                error={errors.phone_3}
                onChange={(value) => {
                  setValue("phone_3", value || "", { shouldValidate: true });
                }}
              />
            </FormRowItem>
          </FormRow>
        </div>
        <div className="mb-8">
          <SectionTitle>Select:</SectionTitle>
          <FormRow>
            <FormRowItem>
              <Controller
                name="select_1"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={selectOptions}
                    required
                    label="Role"
                    onChange={(option) => {
                      setValue("select_1", option.value, { shouldValidate: true });
                    }}
                    supportingText="Choose the role for this user"
                    error={errors.select_1}
                  />
                )}
              />
            </FormRowItem>
            <FormRowItem>
              <Controller
                name="select_2"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    disabled
                    options={selectOptions}
                    label="Options"
                    value={selectOptions[1].value}
                    error={errors.select_2}
                  />
                )}
              />
            </FormRowItem>
          </FormRow>
          <FormRow>
            <FormRowItem>
              <Controller
                name="select_3"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={selectOptions}
                    label="Options"
                    value={selectOptions[2].value}
                    error={errors.select_3}
                  />
                )}
              />
            </FormRowItem>
          </FormRow>
        </div>
        <Submit align="right" />
      </Section>
    </BasePage>
  );
};
