import classNames from "classnames";

export type ButtonGroupProps = React.HTMLAttributes<HTMLDivElement>;

export const ButtonGroup = ({ className, children, ...rest }: ButtonGroupProps) => {
  return (
    <div {...rest} className={classNames("flex rounded-md", className)}>
      {children}
    </div>
  );
};
