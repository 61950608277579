import {
  Gauge,
  Building2,
  UsersRound,
  Car,
  Euro,
  Settings,
  ChartLine,
  NotepadText,
  Component,
  ChevronDown,
} from "lucide-react";
import { X } from "lucide-react";
import { useState, useEffect, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, Link } from "react-router-dom";

import classNames from "classnames";

import { IconButton } from "src/components/ui-components/buttons/Buttons";
import { useSwipe } from "src/hooks";
import type { AppSessionRole } from "src/lib/types";
import { useAppSelector, useAppDispatch } from "src/store";
import { getSessionRoleSelector } from "src/store/selectors";
import { setNavPanelOpened } from "src/store/slices/appearanceSlice";

export type MenuItem =
  | {
      type: "group";
      icon: React.ReactNode;
      title: string;
      children: { title: string; path: string }[];
      collapsed: boolean;
    }
  | {
      type: "item";
      icon: React.ReactNode;
      title: string;
      path: string;
    };

export type MainMenu = MenuItem[];

export type NavigationPanelProps = {
  className?: string;
  onItemClick?: () => void;
} & React.HTMLAttributes<HTMLDivElement>;

const ICON_CLASS = "h-full w-full";

export const NavigationPanel = ({ className, onItemClick, ...rest }: NavigationPanelProps) => {
  const swipeElementRef = useRef<HTMLDivElement>(null);
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const sessionRole = useAppSelector(getSessionRoleSelector);
  const [mainMenu, setMainMenu] = useState<MainMenu>([]);

  useSwipe(swipeElementRef, {
    onSwipe: (direction) => {
      if (direction === "left") {
        dispatch(setNavPanelOpened(false));
      }
    },
  });

  useEffect(() => {
    if (sessionRole === null) return;
    setMainMenu(getMainMenu(sessionRole));
  }, [sessionRole, i18n.language]);

  const getMainMenu = useCallback(
    (sessionRole: AppSessionRole | null): MainMenu => {
      switch (sessionRole) {
        case "admin":
          return [
            {
              type: "group",
              icon: <Gauge className={ICON_CLASS} />,
              title: t("dashboard", "Dashboard"),
              collapsed: true,
              children: [
                {
                  title: "Main",
                  path: `/${sessionRole}/dashboard`,
                },
              ],
            },
            {
              type: "item",
              icon: <Building2 className={ICON_CLASS} />,
              title: t("organization.plural", "Organizations"),
              path: "/admin/organizations",
            },
            {
              type: "item",
              icon: <UsersRound className={ICON_CLASS} />,
              title: t("user.plural", "Users"),
              path: "/admin/users",
            },
            {
              type: "item",
              icon: <ChartLine className={ICON_CLASS} />,
              title: t("report.plural", "Reports"),
              path: "/admin/reports",
            },
            {
              type: "item",
              icon: <Settings className={ICON_CLASS} />,
              title: t("setting.plural", "Settings"),
              path: "/admin/settings",
            },
            {
              type: "group",
              icon: <Component className={ICON_CLASS} />,
              title: "UI Kit",
              collapsed: true,
              children: [
                {
                  title: "Typography",
                  path: "/admin/ui-kit/typography",
                },
                {
                  title: "Form Components",
                  path: "/admin/ui-kit/form-components",
                },
                {
                  title: "Buttons",
                  path: "/admin/ui-kit/buttons",
                },
              ],
            },
          ];
        case "org":
          return [
            {
              type: "item",
              icon: <Gauge className={ICON_CLASS} />,
              title: t("dashboard", "Dashboard"),
              path: `/${sessionRole}/dashboard`,
            },
            {
              type: "item",
              icon: <NotepadText className={ICON_CLASS} />,
              title: t("order.plural", "Orders"),
              path: "/org/orders",
            },
            {
              type: "item",
              icon: <Car className={ICON_CLASS} />,
              title: t("car.plural", "Cars"),
              path: "/org/cars",
            },
            {
              type: "item",
              icon: <UsersRound className={ICON_CLASS} />,
              title: t("user.plural", "Users"),
              path: "/org/users",
            },
            {
              type: "item",
              icon: <Euro className={ICON_CLASS} />,
              title: t("expense.plural", "Expenses"),
              path: "/org/expenses",
            },
            {
              type: "item",
              icon: <ChartLine className={ICON_CLASS} />,
              title: t("report.plural", "Reports"),
              path: "/org/reports",
            },
            {
              type: "item",
              icon: <Settings className={ICON_CLASS} />,
              title: t("setting.plural", "Settings"),
              path: "/org/settings",
            },
          ];
        default:
          return [];
      }
    },
    [sessionRole, i18n.language]
  );

  return (
    <aside
      ref={swipeElementRef}
      {...rest}
      id="MAIN_NAVIGATION_PANEL"
      className={classNames("default-bg shrink-0 flex-col overflow-auto shadow-md", className)}>
      <div className="default-bg sticky top-0 flex h-14 shrink-0 items-center justify-between gap-2 px-2 leading-none">
        <span className="ml-2 font-bold">{t("app_name", "Service Point")}</span>
        <IconButton className="md:hidden" onClick={() => dispatch(setNavPanelOpened(false))}>
          <X />
        </IconButton>
      </div>
      <nav role="desktop-full-navigation-menu" className="overflow-y-scroll p-2">
        <ul className="flex h-full flex-col items-start justify-start gap-1">
          {mainMenu.map((item, index) => {
            if (item.type === "group") {
              const isGroupActive = item.children.some((child) => location.pathname === child.path);
              return (
                <li
                  key={index}
                  className={classNames(
                    "flex w-full flex-col justify-center rounded-md text-sm",
                    isGroupActive &&
                      "bg-gradient-to-r from-sky-200 to-sky-50 dark:from-sky-800 dark:to-transparent"
                  )}>
                  <p
                    onClick={() => {
                      setMainMenu((prev) => {
                        return prev.map((prevItem) => {
                          if (prevItem === item) {
                            return { ...prevItem, collapsed: !prevItem.collapsed };
                          }
                          return prevItem;
                        });
                      });
                    }}
                    className="flex w-full cursor-pointer select-none flex-row items-center justify-start rounded-md p-2">
                    <span className={classNames("h-4 w-4", isGroupActive ? "text-sky-500" : "muted-text")}>
                      {item.icon}
                    </span>
                    <span className="ml-4 block max-w-full truncate text-left text-sm">{item.title}</span>
                    <span className="ml-auto">
                      <ChevronDown
                        className={classNames(
                          "h-5 w-5 transition-transform duration-75",
                          !item.collapsed && "rotate-180"
                        )}
                      />
                    </span>
                  </p>
                  {!item.collapsed ? (
                    <ul className="flex h-full flex-col items-start justify-start gap-1 pl-8">
                      {item.children.map((children) => {
                        const isActive = location.pathname === children.path;
                        return (
                          <li
                            onClick={onItemClick}
                            key={children.path}
                            className={classNames("flex w-full justify-center text-sm")}>
                            <Link
                              to={children.path}
                              className={classNames(
                                "flex w-full select-none flex-row items-center rounded-md p-2",
                                isActive ? "text-default-text" : "muted-text hover:text-default-text"
                              )}>
                              <span className="block max-w-full truncate text-left text-sm">
                                {children.title}
                              </span>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  ) : null}
                </li>
              );
            } else {
              const isActive = location.pathname === item.path;
              return (
                <li
                  onClick={onItemClick}
                  key={index}
                  className={classNames("flex w-full justify-center text-sm")}>
                  <Link
                    to={item.path}
                    className={classNames(
                      "flex w-full select-none flex-row items-center rounded-md p-2",
                      isActive
                        ? "bg-gradient-to-r from-sky-200 to-sky-50 dark:from-sky-800 dark:to-transparent"
                        : "muted-text hover:text-default-text"
                    )}>
                    <span className={classNames("h-4 w-4", isActive ? "text-sky-500" : "muted-text")}>
                      {item.icon}
                    </span>
                    <span className="ml-4 block max-w-full truncate text-left text-sm">{item.title}</span>
                  </Link>
                </li>
              );
            }
          })}
        </ul>
      </nav>
    </aside>
  );
};

export default NavigationPanel;
