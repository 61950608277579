import { Route, Routes, Navigate } from "react-router-dom";

import AdminDashboard from "src/components/features/dashboard/AdminDashboard";
import { OrganizationsPage } from "src/components/features/organization/pages";
import { AdminReportsPage } from "src/components/features/reports/pages";
import { AdminSettingsPage } from "src/components/features/settings/pages";
import { AdminUsersPage } from "src/components/features/users/pages";
import { NotFoundPage } from "src/components/pages";
import UIKitRoutes from "src/components/ui-kit/UIKitRoutes";
import { useAppSelector } from "src/store";
import { getSessionIsAdminSelector } from "src/store/selectors";

export const AdminRoutes = () => {
  const isAdmin = useAppSelector(getSessionIsAdminSelector);

  return isAdmin ? (
    <Routes>
      <Route path="/" element={<Navigate to="/admin/dashboard" />} />
      <Route path="/dashboard" element={<AdminDashboard />} />
      <Route path="/organizations" element={<OrganizationsPage />} />
      <Route path="/users" element={<AdminUsersPage />} />
      <Route path="/reports" element={<AdminReportsPage />} />
      <Route path="/settings" element={<AdminSettingsPage />} />
      <Route path="/ui-kit/*" element={<UIKitRoutes />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  ) : (
    <NotFoundPage />
  );
};
