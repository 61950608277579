import { useTranslation } from "react-i18next";

import classNames from "classnames";
import moment from "moment";

export const DateTime = ({ date, className }: { date: string; className?: string }) => {
  const { i18n } = useTranslation();
  return <time className={classNames("", className)}>{moment(date).locale(i18n.language).format("LL")}</time>;
};

export default DateTime;
