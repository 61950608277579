import { useEffect } from "react";

import { useCoreApi } from "src/hooks";
import { useAppSession } from "src/hooks";
import { useLoading } from "src/hooks";

export const LogoutPage = () => {
  const { startFullScreenLoading, stopFullScreenLoading } = useLoading();
  const { logout } = useCoreApi();
  const { destroyAuthSession } = useAppSession();

  useEffect(() => {
    startFullScreenLoading({ title: "Logging out" });
    logout().finally(() => {
      destroyAuthSession();
      stopFullScreenLoading();
    });
  }, []);

  return null;
};
