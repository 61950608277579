import classNames from "classnames";

import CircleSpinner from "./CircleSpinner";
import { FullScreenLoaderType } from "src/store/slices/loadingSlice";

export const FullScreenLoader = ({
  title,
  subTitle,
  className,
}: FullScreenLoaderType & {
  className?: string;
}) => {
  return (
    <div
      className={classNames("fixed inset-0 z-10 flex h-dvh w-screen items-center justify-center", className)}>
      <div className="absolute inset-0 bg-black opacity-90" />
      <div className="relative z-10 flex flex-col items-center justify-center text-gray-100">
        <h2 className="mb-4 text-center text-3xl lg:text-4xl">{title || "Please wait"}</h2>
        {subTitle && <p className="mb-4 text-center">{subTitle}</p>}
        <div>
          <CircleSpinner size="medium" />
        </div>
      </div>
    </div>
  );
};

export default FullScreenLoader;
