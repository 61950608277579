import { FeaturePage } from "src/components/layout/page-templates";
import { AddNewButton } from "src/components/ui-components/buttons";
import { NoDataFound } from "src/components/ui-components/info";

export const CarsPage = () => {
  const addNewAction = () => {
    console.log("New item handler");
  };

  return (
    <FeaturePage entity="car" total={0} actions={<AddNewButton entity="car" onClick={addNewAction} />}>
      <NoDataFound entity="car" />
    </FeaturePage>
  );
};

export default CarsPage;
