import { Loader } from "lucide-react";
import { useTranslation } from "react-i18next";

import classNames from "classnames";

import { Button } from "src/components/ui-components/buttons";

export type SubmitProps = React.HTMLAttributes<HTMLDivElement> & {
  align?: "left" | "center" | "right";
  fullWidth?: boolean;
  submitText?: string;
  disabled?: boolean;
  loading?: boolean;
};

export const Submit = ({
  align = "center",
  fullWidth,
  submitText,
  disabled,
  loading,
  className,
  ...rest
}: SubmitProps) => {
  const { t } = useTranslation();
  return (
    <div
      {...rest}
      className={classNames(
        "mt-4 flex w-full",
        align === "center" && "justify-center",
        align === "left" && "justify-start",
        align === "right" && "justify-end",
        className
      )}>
      <Button
        disabled={disabled || loading}
        className={classNames(fullWidth && "w-full")}
        type="submit"
        variant="primary"
        icon={
          loading ? (
            <Loader className="h-6 w-6 animate-[spin_2s_ease-in-out_infinite] ease-in-out" />
          ) : undefined
        }
        size="md">
        <>{loading ? t("status.processing", "Processing") : submitText || t("cta.submit", "Submit")}</>
      </Button>
    </div>
  );
};
