import ReactDOM from "react-dom/client";
import { Provider as AppStoreProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import "./i18n";
import { AppAppearance, AppGlobalLayer, AuthSession, AppLayout } from "src/hoc";
import reportWebVitals from "src/measurements/web-vitals/reportWebVitals";
import RootRouter from "src/router/RootRouter";
import store from "src/store/config";
import "src/styles/global.css";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  // <React.StrictMode>
  <AppStoreProvider store={store}>
    <BrowserRouter>
      <AppAppearance>
        <AppGlobalLayer>
          <AuthSession>
            <AppLayout>
              <RootRouter />
            </AppLayout>
          </AuthSession>
        </AppGlobalLayer>
      </AppAppearance>
    </BrowserRouter>
  </AppStoreProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
