import { Languages } from "lucide-react";
import { useTranslation } from "react-i18next";

import { GlobalDropdown } from "src/components/ui-components/dropdowns/GlobalDropdown";
import { STORAGE_KEYS } from "src/lib/constants";

type AppLanguage = {
  abbreviation: string;
  name: string;
  nativeName: string;
  flag: string;
  enabled: boolean;
};

const languages: AppLanguage[] = [
  {
    abbreviation: "en-US",
    name: "English",
    nativeName: "English",
    flag: "http://purecatamphetamine.github.io/country-flag-icons/3x2/US.svg",
    enabled: true,
  },
  {
    abbreviation: "uk-UA",
    name: "Ukrainian",
    nativeName: "Українська",
    flag: "http://purecatamphetamine.github.io/country-flag-icons/3x2/UA.svg",
    enabled: true,
  },
  {
    abbreviation: "pl-PL",
    name: "Polish",
    nativeName: "Polski",
    flag: "http://purecatamphetamine.github.io/country-flag-icons/3x2/PL.svg",
    enabled: true,
  },
  {
    abbreviation: "ru-RU",
    name: "Russian",
    nativeName: "Русский",
    flag: "http://purecatamphetamine.github.io/country-flag-icons/3x2/RU.svg",
    enabled: false,
  },
];

function LanguageSelector() {
  const { i18n } = useTranslation();

  const changeLanguage = (abbr: string) => {
    localStorage.setItem(STORAGE_KEYS.APP_LOCALE, abbr);
    i18n.changeLanguage(abbr);
  };

  return (
    <GlobalDropdown
      icon={<Languages />}
      items={languages.map((lang) => ({
        type: "button",
        action: () => changeLanguage(lang.abbreviation),
        title: lang.nativeName,
        disabled: !lang.enabled,
      }))}
    />
  );
}

export default LanguageSelector;
