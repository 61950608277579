import { UserRound, LogOut, UserCog } from "lucide-react";

import classNames from "classnames";

import { GlobalDropdown } from "src/components/ui-components/dropdowns/GlobalDropdown";
import type { User, Organization } from "src/lib/types";
import { useAppSelector } from "src/store";
import { getSessionSelector } from "src/store/selectors";

const UserInfo = ({ user, organization }: { user: User | null; organization: Organization | null }) => {
  return user ? (
    <div className="text-right text-xs">
      <p>{user.full_name}</p>
      {user.admin && <p className="muted-text mt-1">Administrator</p>}
      {organization && (
        <>
          {organization?.name && <p className="muted-text mt-1">{organization.name}</p>}
          {organization?.user_role && <p className="muted-text mt-1">{organization.user_role}</p>}
        </>
      )}
    </div>
  ) : null;
};

export default function UserMenu({
  children,
  className,
}: {
  children?: React.ReactNode;
  className?: string;
}) {
  const { user, organization } = useAppSelector(getSessionSelector);

  return (
    <GlobalDropdown
      items={[
        {
          type: "node",
          content: <UserInfo user={user} organization={organization} />,
        },
        { type: "divider" },
        { type: "link", to: "/user/profile", title: "Profile", icon: <UserCog /> },
        { type: "divider" },
        { type: "link", to: "/auth/logout", title: "Logout", icon: <LogOut /> },
      ]}>
      <span className={classNames(className)}>
        {children || (
          <button className="relative flex h-9 w-9 items-center justify-center rounded-full bg-gray-200 dark:bg-gray-600">
            <UserRound className="h-6 w-6" />
            <span className="absolute bottom-0 right-0 h-[10px] w-[10px] rounded-full border border-white bg-green-500"></span>
          </button>
        )}
      </span>
    </GlobalDropdown>
  );
}
