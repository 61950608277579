import { useTranslation } from "react-i18next";

import { BasePage } from "src/components/layout/page-templates";
import { useAppSelector } from "src/store";
import { getSessionOrganizationSelector } from "src/store/selectors";

export const CompanySettingsPage = () => {
  const { t } = useTranslation();
  const organization = useAppSelector(getSessionOrganizationSelector);

  return (
    <BasePage
      title={t("setting.plural", "Settings")}
      description={t("setting.description", "Manage your settings")}>
      {organization && (
        <>
          <pre className="mb-5 font-mono text-xs">
            <code>{JSON.stringify(organization, null, 2)}</code>
          </pre>
        </>
      )}
    </BasePage>
  );
};

export default CompanySettingsPage;
