import { Mail, ArchiveX, ArchiveRestore, DoorOpen } from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { OrganizationForm } from "src/components/features/organization/forms/OrganizationForm";
import { FeaturePage } from "src/components/layout/page-templates";
import { AddNewButton } from "src/components/ui-components/buttons";
import { Dialog, openDialogById, closeDialogById } from "src/components/ui-components/dialog/Dialog";
import { InfoLabel } from "src/components/ui-components/info";
import { Section } from "src/components/ui-components/layout-components";
import { Table } from "src/components/ui-components/table";
import { useCoreApi } from "src/hooks";
import { useAppSession } from "src/hooks";
import type { Organization, Pagy } from "src/lib/types";
import type { CreateOrganizationPayload } from "src/lib/types";

const NEW_ORG_DIALOG_ID = "createNewOrg";

export const OrganizationsPage = () => {
  const navigate = useNavigate();
  const { enterOrganization } = useAppSession();
  const { getOrganizationsList, toggleArchiveOrganization, getOrganizationById, createOrganization } =
    useCoreApi();
  const [loading, setLoading] = useState(true);
  const [organizations, setOrganizations] = useState<Organization[] | null>(null);
  const [pagy, setPagy] = useState<Pagy | null>(null);
  const [page, setPage] = useState(1);

  useEffect(() => {
    loadOrganizations();
  }, [page]);

  const loadOrganizations = () => {
    setLoading(true);
    getOrganizationsList({ status: "all", page })
      .then(({ organizations, pagy }) => {
        setPagy(pagy);
        setOrganizations(organizations);
      })
      .finally(() => setLoading(false));
  };

  const handleArchiveOrganization = (organizationId: number, isArchived: boolean) => {
    if (
      !window.confirm(`Are you sure you want to ${isArchived ? "unarchive" : "archive"} this organization?`)
    ) {
      return;
    }
    setLoading(true);
    toggleArchiveOrganization({
      orgId: organizationId,
      isCurrentlyArchived: isArchived,
    })
      .then(() => {
        setOrganizations((orgs) => {
          if (orgs) {
            return orgs.map((org) => {
              if (org.id === organizationId) {
                return { ...org, archived: !org.archived };
              }
              return org;
            });
          }
          return orgs;
        });
      })
      .finally(() => setLoading(false));
  };

  const handleEnterOrganization = (organizationId: number) => {
    setLoading(true);
    getOrganizationById(organizationId)
      .then((org) => {
        enterOrganization(org);
        navigate("/org/orders");
      })
      .finally(() => setLoading(false));
  };

  const onSubmitCreation = (data: CreateOrganizationPayload["organization"]) => {
    setLoading(true);
    createOrganization(data)
      .then(() => navigate("/admin/organizations"))
      .finally(() => {
        setLoading(false);
        closeDialogById(NEW_ORG_DIALOG_ID);
        loadOrganizations();
      });
  };

  return (
    <FeaturePage
      entity="organization"
      total={pagy?.count}
      actions={<AddNewButton entity="organization" onClick={() => openDialogById(NEW_ORG_DIALOG_ID)} />}>
      <Table
        loading={loading && organizations === null}
        pagination={{ pagy, onPageChange: setPage }}
        cols={[
          { key: "name", content: "Name" },
          { key: "owner_email", content: "Owner" },
          { key: "plan", content: "Plan" },
          { key: "archived", content: "Status" },
        ]}
        rows={
          organizations
            ? organizations.map((org) => ({
                id: org.id,
                cells: [
                  {
                    key: "name",
                    content: (
                      <>
                        <p className="font-medium">{org.name}</p>
                        <p className="text-xs text-gray-400">/{org.slug}</p>
                      </>
                    ),
                  },
                  {
                    key: "owner_email",
                    content: (
                      <a className="link-colors flex items-center" href={`mailto:${org.owner_email}`}>
                        <Mail className="h-4" />
                        <span className="ml-1">{org.owner_email}</span>
                      </a>
                    ),
                  },
                  {
                    key: "plan",
                    content: <InfoLabel className="capitalize">{org.plan}</InfoLabel>,
                  },
                  {
                    key: "archived",
                    content: (
                      <InfoLabel color={org.archived ? "danger" : "success"}>
                        {org.archived ? "archived" : "active"}
                      </InfoLabel>
                    ),
                  },
                ],
                actions: [
                  {
                    type: "button",
                    title: org.archived ? "Restore" : "Archive",
                    icon: org.archived ? <ArchiveRestore /> : <ArchiveX />,
                    action: () => handleArchiveOrganization(org.id, org.archived),
                  },
                  {
                    type: "button",
                    title: "Enter organization",
                    icon: <DoorOpen />,
                    action: () => handleEnterOrganization(org.id),
                  },
                ],
              }))
            : []
        }
      />
      <Dialog id={NEW_ORG_DIALOG_ID} dialogTitle="Create a new organization">
        <Section className="w-[600px] max-w-full">
          <OrganizationForm loading={loading} disabled={loading} onSubmit={onSubmitCreation} />
        </Section>
      </Dialog>
    </FeaturePage>
  );
};
