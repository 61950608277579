import communicationSlice from "./slices/communicationSlice";
import { configureStore } from "@reduxjs/toolkit";
import appearanceSlice from "src/store/slices/appearanceSlice";
import loadingSlice from "src/store/slices/loadingSlice";
import sessionSlice from "src/store/slices/sessionSlice";

export const store = configureStore({
  reducer: {
    loading: loadingSlice.reducer,
    appearance: appearanceSlice.reducer,
    session: sessionSlice.reducer,
    communication: communicationSlice.reducer,
  },
});

export default store;
