import { STORAGE_KEYS } from "src/lib/constants";
import { AppAppearanceState } from "src/store/slices/appearanceSlice";
import type { AppTheme } from "src/store/slices/appearanceSlice";

export type ThemeOption = { label: string; value: AppTheme };

export const ALLOWED_THEMES: ThemeOption[] = [
  {
    label: "Light",
    value: "light",
  },
  {
    label: "Dark",
    value: "dark",
  },
];

export const getSystemTheme = (): AppTheme => {
  if (typeof window !== "undefined") {
    return window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
  } else {
    return "light";
  }
};

export const getSavedTheme = (): AppTheme | null => {
  if (typeof window !== "undefined") {
    return localStorage.getItem(STORAGE_KEYS.APP_THEME) as AppAppearanceState["currentTheme"];
  } else {
    return null;
  }
};

export const getCurrentTheme = (): AppTheme => {
  const savedTheme = getSavedTheme();
  if (savedTheme) {
    return savedTheme;
  }
  return getSystemTheme();
};

export const saveSelectedTheme = (theme: AppTheme): void => {
  if (typeof window !== "undefined") {
    return localStorage.setItem(STORAGE_KEYS.APP_THEME, theme);
  }
};

export const saveNavbarState = (state: boolean) => {
  if (typeof window !== "undefined") {
    localStorage.setItem(STORAGE_KEYS.APP_NAVBAR, state ? "true" : "false");
  }
};

export const getSavedNavbarState = (): boolean => {
  if (typeof window !== "undefined") {
    return localStorage.getItem(STORAGE_KEYS.APP_NAVBAR) === "true";
  }
  return false;
};

export const detectOSPlatform = (): AppAppearanceState["platform"] => {
  let os: AppAppearanceState["platform"] = "unknown";

  if (typeof window !== "undefined" && typeof navigator !== "undefined") {
    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;

    const macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K", "macOS"];
    const windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"];
    const iosPlatforms = ["iPhone", "iPad", "iPod"];

    if (macosPlatforms.includes(platform)) {
      os = "mac";
    } else if (iosPlatforms.includes(platform) || /iPhone|iPad|iPod/.test(userAgent)) {
      os = "ios";
    } else if (windowsPlatforms.includes(platform)) {
      os = "windows";
    } else if (/Android/.test(userAgent)) {
      os = "android";
    } else if (/Linux/.test(platform)) {
      os = "linux";
    }
  } else {
    console.error("Error in platform detection: window or navigator is not defined.");
  }

  return os;
};

export const detectMobileDevice = (): boolean => {
  // return true;
  if (typeof navigator === "undefined") {
    return false;
  }
  const userAgent = navigator.userAgent;
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
  return isMobile;
};
