import { MoonStar, Sun } from "lucide-react";

import { IconButton } from "src/components/ui-components/buttons/Buttons";
import { useAppearance } from "src/hooks";
import type { AppAppearanceState } from "src/store/slices/appearanceSlice";

const ICONS: Record<AppAppearanceState["currentTheme"], React.ReactNode> = {
  light: <Sun />,
  dark: <MoonStar />,
};

export default function ThemeSelector() {
  const { currentTheme, allowedThemes, selectTheme } = useAppearance();
  return (
    <IconButton
      aria-label="Select theme"
      onClick={() => {
        const currentIndex = allowedThemes.findIndex((theme) => theme.value === currentTheme);
        const nextIndex = (currentIndex + 1) % allowedThemes.length;
        selectTheme(allowedThemes[nextIndex].value);
      }}>
      {ICONS[currentTheme]}
    </IconButton>
  );
}
