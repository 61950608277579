import { useTranslation } from "react-i18next";

import { BasePage } from "src/components/layout/page-templates";
import { useAppSelector } from "src/store";
import { getSessionOrganizationSelector } from "src/store/selectors";

const OrganizationDashboard = () => {
  const { t } = useTranslation();
  const organization = useAppSelector(getSessionOrganizationSelector);

  return (
    <BasePage title={t("dashboard")} description={organization?.name}>
      {organization && (
        <>
          <p className="mb-4 font-bold">{t("organization.singular", "organization")}:</p>
          <pre className="mb-5 font-mono text-xs">
            <code>{JSON.stringify(organization, null, 2)}</code>
          </pre>
        </>
      )}
    </BasePage>
  );
};

export default OrganizationDashboard;
